import { FormattedMessage } from 'react-intl';

import { Box, Card, List, Typography } from '@material-ui/core';
import ClaimMessage from 'components/ClaimMessage';

import { IClaimMessage } from 'services/claim/types';

import { useStyles } from './styles';

interface ClaimHistoryProps {
  messages: IClaimMessage[];
}

export default function ClaimHistory({ messages }: ClaimHistoryProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <Box>
        <Typography variant="body2" style={{ fontWeight: 700 }}>
          <FormattedMessage id="filed-claim-section.claim-history.history" defaultMessage="History"></FormattedMessage>
        </Typography>
        <Box maxHeight={270} overflow="auto">
          <List>
            {messages.length ? (
              messages.map((message, index) => <ClaimMessage message={message} key={index} />)
            ) : (
              <Typography variant="body2">
                <FormattedMessage
                  id="filed-claim-section.claim-history.no-history"
                  defaultMessage="There are no messages to display."
                ></FormattedMessage>
              </Typography>
            )}
          </List>
        </Box>
      </Box>
    </Card>
  );
}
