import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { StandardTextFieldProps, TextField, InputAdornment } from '@material-ui/core';

export type ValidatedTextFieldState = {
  value: string;
  invalid?: boolean;
};

interface IValidatedTextField {
  validation?: RegExp;
  esChange: (state: ValidatedTextFieldState) => void;
  esBlur?: () => void;
}

export const useStyles = makeStyles(() => ({
  adornment: {
    minWidth: 24,
  },
}));

export default function ValidatedTextField(props: StandardTextFieldProps & IValidatedTextField) {
  const classes = useStyles();
  const [warnInvalid, setWarnInvalid] = useState(false);
  const { validation, esChange, esBlur, ...tfProps } = props;

  function handleChange(event: React.FormEvent<HTMLElement>): void {
    setWarnInvalid(false);

    const { value } = event.target as HTMLInputElement;
    const invalid = validation instanceof RegExp ? !validation.test(value) : undefined;

    if (esChange) esChange({ value, invalid });
  }

  function handleBlur(event: React.FocusEvent<HTMLElement>): void {
    const { value } = event.target as HTMLInputElement;
    const invalid = validation instanceof RegExp ? !validation.test(value) : undefined;

    if (invalid) setWarnInvalid(true);
    if (esBlur) esBlur();
  }

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end" className={classes.adornment}>
        {warnInvalid || props.error ? <ErrorIcon color="error" fontSize="small" /> : <span />}
      </InputAdornment>
    ),
    ...props.InputProps,
  };

  return (
    <TextField
      variant="outlined"
      {...tfProps}
      InputProps={inputProps}
      InputLabelProps={{ shrink: true }}
      onChange={handleChange}
      onBlur={handleBlur}
      error={warnInvalid || props.error}
    />
  );
}
