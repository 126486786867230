import { Stepper, Step, StepLabel } from '@material-ui/core';

interface StepTrackerProps {
  steps: number;
  currentStep: number;
}

export default function StepTracker(props: StepTrackerProps) {
  const { steps, currentStep } = props;

  return (
    <Stepper activeStep={currentStep - 1}>
      {Array.apply(null, Array(steps || 0)).map((_unused, i) => (
        <Step key={i}>
          <StepLabel></StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
