import { useState, useEffect } from 'react';

import { Grid, Box } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import ClaimResponse from 'components/ClaimResponse';
import ClaimHistory from 'components/ClaimHistory';
import AlertNotification from 'components/AlertNotification';

import { Policy, ClaimStatus, PaymentState, ReviewState, IClaimMessage } from 'services/claim/types';

interface FiledClaimSectionProps {
  policy: Policy;
  onClaimStatusChange: (status: ClaimStatus | PaymentState | ReviewState) => void;
  onNumberOfPhotosUploadedChange: (isDelete?: boolean) => void;
}

export default function FiledClaimSection({
  policy,
  onClaimStatusChange,
  onNumberOfPhotosUploadedChange,
}: FiledClaimSectionProps) {
  const { claim } = policy;
  const [claimMessages, setClaimMessages] = useState<IClaimMessage[]>([]);
  const [availableEvents, setAvailableEvents] = useState<string[]>([]);

  useEffect(() => {
    if (policy?.claim) {
      setClaimMessages(policy.claim.claim_messages);
      setAvailableEvents(policy.claim.available_events);
    }
  }, [policy?.claim]);

  if (!claim) return null;

  function updateClaimMessages(claimMessages: IClaimMessage[]) {
    setClaimMessages(claimMessages);
  }

  function updateAvailableEvents(availableEvents: string[]) {
    setAvailableEvents(availableEvents);
  }

  function getLatestMessage() {
    const historyMessages = claimMessages.filter(({ user_role }) => user_role !== 'claimee');
    return historyMessages[0] ?? '';
  }

  return (
    <Box mt={1} clone>
      <Grid container direction="column" spacing={2}>
        {claim.claim_type === 'damaged_or_missing' && claim.review_state !== 'denied' && (
          <Grid item>
            <AlertNotification type="info">
              <FormattedMessage
                id="filed-claim-section.damaged-items-note"
                defaultMessage="Please hold the damaged items until the claim is resolved. Failure to do so will result in the claim denied."
              />
            </AlertNotification>
          </Grid>
        )}

        {claim.review_state === 'denied' && (
          <Grid item>
            <AlertNotification type="info">
              <FormattedMessage
                id="filed-claim-section.claim-denied-note"
                defaultMessage="We're sorry to inform you that after reviewing the claim, we have determined that this shipment is not eligible for reimbursement. See claim details for additional information on why this claim was denied. Please contact support if you have any further queries."
              />
            </AlertNotification>
          </Grid>
        )}

        {!!availableEvents.length && (
          <Grid item>
            <ClaimResponse
              policy={policy}
              latestMessage={getLatestMessage()}
              onClaimStatusChange={onClaimStatusChange}
              onNumberOfPhotosUploadedChange={onNumberOfPhotosUploadedChange}
              onClaimMessagesChange={updateClaimMessages}
              updateAvailableEvents={updateAvailableEvents}
              availableEvents={availableEvents}
            />
          </Grid>
        )}

        {claimMessages && (
          <Grid item>
            <ClaimHistory messages={claimMessages} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
