import COLORS from 'config/colors';

interface UncheckedIconProps {
  error?: boolean;
}

export default function UncheckedIcon(props: UncheckedIconProps) {
  const { error } = props;
  const styleConfig = {
    stroke: error ? COLORS.redDark : COLORS.skyDark,
    fill: error ? COLORS.redLight : COLORS.white,
  };

  return (
    <svg height="26" width="26">
      <circle cx="13" cy="13" r="12" stroke={styleConfig.stroke} strokeWidth="1" fill={styleConfig.fill} />
    </svg>
  );
}
