import ClaimResource from './resource';
import { Policy, ClaimPayload, Claim, ClaimType, ClaimResponse } from './types';

const API_BASE = process.env.REACT_APP_API_BASE;

async function find(query: string, email: string): Promise<{ policies: Policy[] }> {
  return ClaimResource.find(encodeURIComponent(query), encodeURIComponent(email));
}

async function findWithJwt(jwt: string): Promise<{ policies: Policy[] }> {
  return ClaimResource.findWithJwt(encodeURIComponent(jwt));
}

async function create(shipmentId: string, claimType: ClaimType): Promise<{ claim: Claim }> {
  return ClaimResource.create(shipmentId, claimType);
}

async function update(
  shipmentId: string,
  payload: ClaimPayload | { claim_type: ClaimType; id: string },
): Promise<{ claim: Claim }> {
  return ClaimResource.update(shipmentId, payload);
}

async function submit(
  shipmentId: string,
  payload: ClaimPayload | { claim_type: ClaimType; id: string },
): Promise<{ claim: Claim }> {
  return ClaimResource.submit(shipmentId, payload);
}

async function respondClaim(shipmentId: string, payload: ClaimResponse): Promise<{ claim: Claim }> {
  return ClaimResource.respondClaim(shipmentId, payload);
}

// This attaches photos to the claim- there's no need to include photos in a separate update() call
const getUploadEndpoint = (shipmentId: string): string => `${API_BASE}/v1/buyer/shipments/${shipmentId}/claim_photos`;

async function deletePhoto(jwt: string, shipment_id: string, photoId: string): Promise<void> {
  await ClaimResource.deletePhoto(jwt, shipment_id, photoId).catch((error) => {
    console.warn('do something:', error);
  });
}

const ClaimService = { find, findWithJwt, create, update, submit, getUploadEndpoint, respondClaim, deletePhoto };
export default ClaimService;
