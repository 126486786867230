import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& p': {
      margin: 0,
    },
  },
  label: {
    minWidth: 70,
  },
  itemList: {
    '& div:not(:last-child)': {
      marginBottom: 10,
    },
  },
  summaryCard: {
    maxWidth: 500,
    padding: '5px 20px',
    borderRadius: 8,
    boxShadow: 'none',
    opacity: 1,
    marginTop: 10,
  },
  successContainer: {
    padding: '25px 0 5px',
  },
  infoTitle: {
    width: 155,
  },
  claimInfoContainer: {
    padding: '10px 0 20px 0',
    '& .MuiGrid-container': {
      marginTop: 10,
    },
  },
  showDetails: {
    fontWeight: 700,
    textTransform: 'uppercase',

    '& h5': {
      width: 150,
      margin: '10px auto',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },
  shipmentDetails: {
    padding: '10px 0 15px 0',
    '& .MuiGrid-container': {
      marginTop: 10,
    },
  },
  shipmentDetailContainer: {
    marginBottom: 10,
  },
  itemsAffected: {
    width: 30,
  },
}));
