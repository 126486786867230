import { IntlProvider } from 'react-intl';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import './config/sentry';
import { theme } from './config/theme';
import { getLocale, messageDictionary, AvailableLocale } from './config/locale';
import SessionContext from './config/session';

import Home from './screens/Home';
import Claim from './screens/Claim';
import Select from './screens/Select';
import Lost from './screens/Lost';
import Damaged from './screens/Damaged';
import ClaimSummary from './screens/ClaimSummary';
import NotEligible from './screens/NotEligible';

function App() {
  const locale: AvailableLocale = getLocale();

  // Reset state when page is refreshed
  window.history.replaceState({}, document.title);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <IntlProvider locale={locale} messages={messageDictionary[locale] as Record<string, string>}>
        <SessionContext.Provider value={localStorage.email}>
          <BrowserRouter>
            <Switch>
              <Route path="/claim/not-eligible">
                <NotEligible />
              </Route>
              <Route path="/claim/claim-summary">
                <ClaimSummary />
              </Route>
              <Route path="/claim/lost">
                <Lost />
              </Route>
              <Route path="/claim/damaged">
                <Damaged />
              </Route>
              <Route path="/claim/select">
                <Select />
              </Route>
              <Route path="/claim">
                <Claim />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </BrowserRouter>
        </SessionContext.Provider>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
