import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 500,
    padding: 20,
    borderRadius: 8,
    boxShadow: 'none',
    opacity: 1,
    marginTop: 10,

    '& p': {
      margin: 0,
    },
  },
}));
