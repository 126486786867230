import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  mainContainer: {
    padding: '30px',
  },
  header: {
    width: '100%',
  },
  boxes: {
    width: '200px',
  },
  formContainer: {
    marginTop: '10px',
    width: '100%',
  },
  subtitle: {
    margin: '0 10px',
    maxWidth: 400,
  },
}));
