import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 500,
    padding: 20,
    borderRadius: 8,
    boxShadow: 'none',
    opacity: 1,
    marginTop: 10,

    '& p': {
      margin: 0,
    },
  },
  textField: {
    '& .MuiInputBase-root': {
      alignItems: 'start',
      margin: '15px 0',
    },

    '& .MuiOutlinedInput-multiline': {
      padding: 10,
      height: 140,
    },

    '& .MuiInputAdornment-root': {
      display: 'none',
    },

    '& .MuiInputBase-input': {
      height: '100% !important',
      overflow: 'auto !important',
    },
  },
}));
