import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {},
  logo: {
    maxWidth: '50vw',
    width: '140px',
  },
}));

export default function Header(props: any) {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Grid container direction="column" alignItems="center" spacing={2}>
      <Grid item>
        <img
          className={classes.logo}
          src="/images/logos/easyship.svg"
          alt={intl.formatMessage({ id: 'global.easyship', defaultMessage: 'Easyship' })}
        ></img>
      </Grid>

      <Grid item>
        <Typography variant="h2" component="h1">
          {props.children}
        </Typography>
      </Grid>
    </Grid>
  );
}
