import { useState, SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import AlertNotification from 'components/AlertNotification/index';
import ValidatedTextField, { ValidatedTextFieldState } from 'components/ValidatedTextField';

import RecaptchaService from 'services/recaptcha';
import ClaimService from 'services/claim';
import { isPast } from 'services/date';
import { Collapse } from '@material-ui/core';

import { HEADERS } from 'services/claim/resource';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTextField-root, & .MuiButtonBase-root': {
      width: '100%',
    },
    width: '100%',
    maxWidth: 340,
    marginTop: 15,
  },
  field: {
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
  },
}));

export default function ShipmentForm() {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const [query, setQuery] = useState<ValidatedTextFieldState>({ value: '', invalid: true });
  const [email, setEmail] = useState<ValidatedTextFieldState>({ value: '', invalid: true });
  const [alertMessage, setAlertMessage] = useState('');
  const regexEmailFormat = /^.+@.+\..+$/;

  async function validateForm(event: SyntheticEvent) {
    event.preventDefault();

    if (query.invalid || email.invalid) {
      setAlertMessage('invalid');
    }

    HEADERS['recaptcha-token'] = await RecaptchaService.execute();

    ClaimService.find(query.value, email.value).then(({ policies }) => {
      if (!policies?.length) return setAlertMessage('no-policies');
      if (policies.every(({ shipment }) => isPast(shipment.claim_filing_window_closes))) {
        return history.push('/claim/not-eligible', { pastFilingWindow: true });
      }
      if (policies.length > 1) return history.push('/claim/select', { policies });
      return policies[0].claim?.check_certify
        ? history.push('/claim/claim-summary', { policy: policies[0] })
        : history.push('/claim', { policy: policies[0] });
    }).catch(() => {
      setAlertMessage('default');
    })
  };

  return (
    <form onSubmit={validateForm} className={classes.root}>
      <ValidatedTextField
        label={intl.formatMessage({ id: 'form.tracking', defaultMessage: 'Order or Tracking No.' })}
        value={query.value}
        esChange={setQuery}
        validation={/.+/}
        className={classes.field}
      />
      <ValidatedTextField
        label={intl.formatMessage({ id: 'global.email', defaultMessage: 'Email' })}
        value={email.value}
        esChange={setEmail}
        validation={regexEmailFormat}
        className={classes.field}
      />

      <Collapse in={alertMessage === 'invalid'}>
        <AlertNotification type="warning">
          <FormattedMessage id="home.invalid_alert" defaultMessage="Email format is incorrect." />
        </AlertNotification>
      </Collapse>

      <Collapse in={alertMessage === 'no-policies'}>
        <AlertNotification type="warning">
          <FormattedMessage
            id="home.no_shipments_found"
            defaultMessage="No shipments matches this tracking number and email."
          />
        </AlertNotification>
      </Collapse>

      <Collapse in={alertMessage === 'default'}>
        <AlertNotification type="warning">
          <FormattedMessage
            id="global.default-error"
            defaultMessage="Something went wrong. Please refresh the page and try again."
          />
        </AlertNotification>
      </Collapse>

      <Button color="primary" type="submit" disabled={query.invalid || email.invalid} className={classes.button}>
        <FormattedMessage id="global.next" defaultMessage="Next"></FormattedMessage>
      </Button>
    </form>
  );
}
