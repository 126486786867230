import { makeStyles } from '@material-ui/core/styles';

import COLORS from '../config/colors';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    margin: '0 auto',
    width: 25,
    height: 25,
    backgroundColor: COLORS.greenDark,
    borderRadius: '100%',
    animation: '$scaleout 1.0s infinite ease-in-out',
  },
  '@keyframes scaleout': {
    '0%': {
      transform: 'scale(0.0)',
    },
    '100%': {
      transform: 'scale(1.0)',
      opacity: 0,
    },
  },
}));

export default function Thumper() {
  const classes = useStyles();

  return <div className={classes.root}></div>;
}
