import { Typography } from '@material-ui/core';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { ShipmentItem } from 'services/claim/types';

export default function ShipmentItemSummary(props: { item: ShipmentItem }) {
  const { item } = props;

  return (
    <>
      <Typography color="textPrimary">{item.description}</Typography>
      <Typography color="textSecondary" className="descriptionItem">
        <FormattedNumber
          value={item.claim_declared_customs_value}
          style="currency"
          currencyDisplay="code"
          currency={item.claim_declared_currency}
        />{' '}
        x {item.quantity}
        <FormattedMessage id="global.quantity_unit" defaultMessage="pcs" /> ={' '}
        <FormattedNumber
          value={item.claim_declared_customs_value * item.quantity}
          style="currency"
          currencyDisplay="code"
          currency={item.claim_declared_currency}
        />
      </Typography>
    </>
  );
}
