import { FormattedMessage, FormattedDate } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import EasyshipHeader from 'components/EasyshipHeader';
import Button from 'components/Button';

import { useStyles } from './styles';

interface INotEligibleScreenState {
  claim_filing_window_opens: string; //ISO date
  daysUntilWindowOpens: number;
  beforeFilingWindow: boolean;
  pastFilingWindow: boolean;
}

export default function NotEligibleScreen() {
  const { state } = useLocation();
  const classes = useStyles();
  const history = useHistory();

  const {
    beforeFilingWindow,
    pastFilingWindow,
    daysUntilWindowOpens,
    claim_filing_window_opens,
  } = state as INotEligibleScreenState;

  if (!beforeFilingWindow && !pastFilingWindow) {
    history.push('/');
    return null;
  }

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.mainContainer}>
      <Grid item>
        <EasyshipHeader>
          <FormattedMessage id="home.headline" defaultMessage="Resolve an Issue" />
        </EasyshipHeader>
      </Grid>

      <Grid item>
        <img className={classes.boxes} src="/images/home/boxes.png" alt="missing or damaged shipments"></img>
      </Grid>

      <Grid item className={classes.subtitle}>
        <Typography variant="subtitle1" align="center">
          {pastFilingWindow && (
            <FormattedMessage
              id="not-eligible.not-eligible-note"
              defaultMessage="A claim for any shipment must be filed within 90 days of shipment date. Unfortunately, it has past the filing window for your shipment."
            />
          )}
          {daysUntilWindowOpens > 0 && (
            <FormattedMessage
              id="not-eligible.not-yet-note"
              defaultMessage="There {daysUntilWindowOpens, select, one{is} other {are}} still {daysUntilWindowOpens, plural, one{# day} other {# days}} before you can file this claim. You may come back on {date} if your shipment is still not delivered by then."
              values={{
                daysUntilWindowOpens,
                date: (
                  <FormattedDate
                    value={new Date(claim_filing_window_opens)}
                    year="numeric"
                    month="short"
                    day="2-digit"
                  />
                ),
              }}
            />
          )}
        </Typography>
      </Grid>

      <Grid item>
        <Grid container direction="column" justifyContent="center">
          <Button color="secondary" onClick={history.goBack}>
            <FormattedMessage id="global.back" defaultMessage="Back"></FormattedMessage>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
