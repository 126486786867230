import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '25px 20px',
    minWidth: 400,
    maxWidth: 500,
  },
  summaryCard: {
    padding: '5px 20px',
    borderRadius: 8,
    boxShadow: 'none',
    opacity: 1,
    marginTop: 10,
  },
  fullWidth: {
    width: '100%',
  },
  logo: {
    maxWidth: '80vw',
    width: '250px',
  },
  boxes: {
    width: '200px',
  },
  subheader: {
    margin: '25px 0',
  },
  PopButtonWrapper: {
    width: '47%',
  },
}));
