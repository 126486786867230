import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Divider, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import ClaimStatusChip from 'components/ClaimStatusChip';
import ItemsList from 'components/ItemsList';

import { ShipmentItem, ClaimStatus, Tracking, ReviewState, PaymentState } from 'services/claim/types';

interface ShipmentInfoCardProps {
  claimed?: boolean;
  claimStatus?: ClaimStatus | ReviewState | PaymentState;
  trackingData: Tracking[];
  items: ShipmentItem[];
  handleClick?: () => void;
}

export default function ShipmentInfoCard(props: ShipmentInfoCardProps) {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      height: '100%',
      width: '100%',
      padding: '0 20px',
      marginBottom: 20,
      boxShadow: '0px 2px 15px #284E661A',
      borderRadius: 8,
      transition: 'top ease 0.5s',
      '&:hover': {
        bottom: 2,
        cursor: 'pointer',
      },
    },
    labelContainer: {
      margin: '15px 0',
    },
    label: {
      minWidth: 55,
    },
    itemListContainer: {
      padding: '15px 0',
    },
    claimStatusContainer: {
      marginBottom: 5,
    },
    claimStatus: {
      fontWeight: 700,
    },
    claimStatusChipContainer: {
      marginLeft: 5,
    },
  }));

  const classes = useStyles();
  const { claimed, claimStatus, trackingData, items, handleClick } = props;
  let latestTrackingInfo;

  if (trackingData.length) {
    latestTrackingInfo = trackingData.reduce((latestTracking, tracking) => {
      return tracking.leg_number > latestTracking.leg_number ? tracking : latestTracking;
    });
  }

  return (
    <Card className={classes.root} onClick={handleClick}>
      <Grid container direction="column" alignItems="center" className={classes.labelContainer}>
        {claimed && claimStatus && (
          <Grid container direction="row" alignItems="center" className={classes.claimStatusContainer}>
            <Grid item>
              <Typography variant="body1" className={classes.claimStatus}>
                <FormattedMessage id="select.claim_status" defaultMessage="Claim Status:"></FormattedMessage>
              </Typography>
            </Grid>
            <Grid item className={classes.claimStatusChipContainer}>
              <ClaimStatusChip status={claimStatus}></ClaimStatusChip>
            </Grid>
          </Grid>
        )}
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1">
              <FormattedMessage id="select.tracking_number" defaultMessage="Tracking Number:"></FormattedMessage>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2">
              {latestTrackingInfo ? latestTrackingInfo.tracking_number : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      {items && (
        <Grid container direction="row" wrap="nowrap" className={classes.itemListContainer}>
          <Grid item className={classes.label}>
            <Typography variant="body1">
              <FormattedMessage id="global.items" defaultMessage="Items:"></FormattedMessage>
            </Typography>
          </Grid>
          <Grid item>
            <ItemsList items={items} />
          </Grid>
        </Grid>
      )}
    </Card>
  );
}
