import { makeStyles } from '@material-ui/core/styles';

import COLORS from 'config/colors';

const useStyles = makeStyles(() => ({
  dropzone: {
    width: '100%',
    height: 100,
    border: `1px solid ${COLORS.skyDark}`,
    borderRadius: 4,
  },
  preview: {
    // In the future, we could replace this with a slimmer UI
    display: 'none',
  },
  submitButtonContainer: {
    display: 'none',
  },

  label: {
    height: '100%',
    minWidth: '120px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    color: COLORS.inkLight,
    fill: COLORS.inkLight,
    cursor: 'pointer',
    transition: 'padding-left 2s ease-in-out 2s',
  },
  icon: {
    margin: '0 auto',
  },
  withOnePhoto: {
    paddingLeft: 116,
  },
  withPhotos: {
    paddingLeft: 'calc(100% - 120px)',
  },

  carousel: {
    height: '100%',
    maxWidth: 'calc(100% - 120px)',
    marginTop: -100,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
  },
  ImageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
}));

export default useStyles;
