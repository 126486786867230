import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '25px 20px',
    minWidth: 400,
    maxWidth: 500,
  },
  summaryCard: {
    padding: '5px 20px 5px',
    borderRadius: 8,
    boxShadow: 'none',
    opacity: 1,
    marginTop: 10,
  },
  subheader: {
    margin: '25px 0',
  },
}));
