import Cookies from 'universal-cookie';

import fr from 'translations/fr.json';

const cookies = new Cookies();

export const messageDictionary = { en: {}, fr };
export enum AvailableLocale {
  English = 'en',
  French = 'fr',
}

export const LOCALES = Array.from(Object.values(AvailableLocale));
export const DEFAULT_LOCALE = 'en';

export function getLocale() {
  const savedLocale = cookies.get('locale');

  if (LOCALES.includes(savedLocale)) return savedLocale;

  const matchingLocale = navigator.languages.reduce((match, cur) => {
    if (match) return match;
    if (LOCALES.includes(cur as AvailableLocale)) return cur;

    return LOCALES.find((availableLocale) => cur.slice(0, availableLocale.length) === availableLocale) || '';
  }, '');

  if (matchingLocale) {
    cookies.set('locale', matchingLocale);
    return matchingLocale;
  }

  return DEFAULT_LOCALE;
}

// export function loadLocaleData(locale: string) {
//   switch (locale) {
//     case "fr":
//       return import("../translations/fr.json");
//     default:
//     // return import('../compiled-lang/en.json');
//   }
// }
