/**
 * RecaptchService
 *
 * @see https://developers.google.com/recaptcha/docs/v3
 */

declare global {
  interface Window {
    onLoadCaptchaV3Callback: () => void;
    grecaptcha: {
      ready: (callback: () => void) => void;
      execute: (key: string, opts?: { action?: string }) => Promise<string>;
    };
  }
}

export const init = (executeOnLoad = false) =>
  new Promise((resolve, reject) => {
    if (!process.env.REACT_APP_RECAPTCHA_KEY) {
      return reject('Please set the REACT_APP_RECAPTCHA_KEY environment variable');
    }

    const script = document.createElement('script');

    if (!window.onLoadCaptchaV3Callback && executeOnLoad) {
      window.onLoadCaptchaV3Callback = () => {
        window.grecaptcha.ready(() =>
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_KEY as string, { action: 'load' })
            .then(resolve)
            .catch(reject),
        );
      };
    }

    script.src = `https://www.recaptcha.net/recaptcha/api.js?${
      executeOnLoad ? 'onload=onLoadCaptchaV3Callback&' : ''
    }render=${process.env.REACT_APP_RECAPTCHA_KEY}`;
    document.body.appendChild(script);
  });

export const execute = (action?: string): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!process.env.REACT_APP_RECAPTCHA_KEY) {
      return reject('Please set the REACT_APP_RECAPTCHA_KEY environment variable');
    }

    window.grecaptcha.ready(() =>
      window.grecaptcha
        .execute(process.env.REACT_APP_RECAPTCHA_KEY as string, { action })
        .then(resolve)
        .catch(reject),
    );
  });

export default { init, execute };
