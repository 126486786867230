import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { ShipmentItem } from 'services/claim/types';
import ShipmentItemSummary from 'components/ShipmentItemSummary';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    '& p': {
      margin: 0,
    },
    '& div:not(:last-child)': {
      marginBottom: 10,
    },
    '& .descriptionItem:not(:last-child)': {
      marginBottom: 12,
    },
  },
}));

export default function ItemsList(props: { items: ShipmentItem[] }) {
  const { items } = props;
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      {items.map((item: ShipmentItem) => {
        return <ShipmentItemSummary item={item} key={item.id} />;
      })}
    </Container>
  );
}
