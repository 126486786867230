import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Container, Typography, Grid, Snackbar } from '@material-ui/core';

import AlertNotification from 'components/AlertNotification/index';
import ClaimSummary from 'components/ClaimSummary';
import FooterLinks from 'components/FooterLinks';
import FiledClaimSection from 'components/FiledClaimSection';
import { Policy, Claim, ClaimStatus, PaymentState, ReviewState, AllClaimStatuses } from 'services/claim/types';
import { HEADERS } from 'services/claim/resource';

import { useStyles } from './styles';

interface IClaimSummaryScreenState {
  policy?: Policy;
  showSuccessBanner: boolean;
}

export default function ClaimSummaryScreen() {
  const history = useHistory();
  const classes = useStyles();
  const { policy, showSuccessBanner } = (useLocation().state || {}) as IClaimSummaryScreenState;
  const [claimStatus, setClaimStatus] = useState<AllClaimStatuses>('');
  const [numberOfPhotosUploaded, setNumberOfPhotosUploaded] = useState(0);
  const [showPhotoUploadedAlert, setShowPhotoUploadedAlert] = useState(false);
  const [showUpdatedClaimStateAlert, setShowUpdatedClaimStateAlert] = useState(false);

  useEffect(() => {
    if (policy?.claim) {
      setClaimStatus(getClaimStatus(policy.claim));
      setNumberOfPhotosUploaded(policy.claim.all_photos.length);
      if (!HEADERS['jwt-token']) HEADERS['jwt-token'] = policy.jwt.token;
    }
  }, [policy?.claim]);

  function getClaimStatus(claim: Claim) {
    return claim.processor === 'insureship'
      ? claim.provider_claim_status
      : claim.review_state === 'approved'
      ? claim.payment_state
      : claim.review_state;
  }

  function updateClaimStatus(status: ClaimStatus | PaymentState | ReviewState) {
    setClaimStatus(status);
    setShowUpdatedClaimStateAlert(true);
  }

  function updateNumberOfPhotosUploaded(isDelete?: boolean) {
    const newNumberOfPhotosUploaded = isDelete ? numberOfPhotosUploaded - 1 : numberOfPhotosUploaded + 1;
    setNumberOfPhotosUploaded(newNumberOfPhotosUploaded);
    setShowPhotoUploadedAlert(true);
  }

  if (!policy) {
    history.push('/');
    return null;
  }

  return (
    <Container className={classes.root}>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.headerContainer}>
        <Grid item>
          <img className={classes.logo} src="/images/logos/easyship.svg" alt="Easyship Logo" />
        </Grid>
        <Grid item>
          <Typography variant="h3">
            <FormattedMessage id="claim-summary.header" defaultMessage="Claim Summary"></FormattedMessage>
          </Typography>
        </Grid>
      </Grid>

      <ClaimSummary
        policy={policy}
        showSuccessBanner={showSuccessBanner}
        claimStatus={claimStatus}
        numberOfPhotosUploaded={numberOfPhotosUploaded}
      />

      {policy.claim?.processor === 'easyship' && (
        <FiledClaimSection
          policy={policy}
          onClaimStatusChange={updateClaimStatus}
          onNumberOfPhotosUploadedChange={updateNumberOfPhotosUploaded}
        />
      )}

      <FooterLinks companyName={policy.company.name}></FooterLinks>

      <Snackbar open={showPhotoUploadedAlert} autoHideDuration={3000} onClose={() => setShowPhotoUploadedAlert(false)}>
        <AlertNotification type="success">
          <FormattedMessage id="claim-summary.photo-uploaded-alert" defaultMessage="Photo uploaded successfully!" />
        </AlertNotification>
      </Snackbar>

      <Snackbar
        open={showUpdatedClaimStateAlert}
        autoHideDuration={3000}
        onClose={() => setShowUpdatedClaimStateAlert(false)}
      >
        <AlertNotification type="success">
          <FormattedMessage id="claim-summary.claim-state-success-alert" defaultMessage="Your message has been sent!" />
        </AlertNotification>
      </Snackbar>
    </Container>
  );
}
