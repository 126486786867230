import { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Card, Typography, Grid, FormControl, FormControlLabel, RadioGroup, Radio, Box } from '@material-ui/core';
import ValidatedTextField, { ValidatedTextFieldState } from 'components/ValidatedTextField';
import Uploader from 'components/Uploader';
import Button from 'components/Button';
import AlertNotification from 'components/AlertNotification';

import ClaimService from 'services/claim';
import {
  Policy,
  Photo,
  IClaimMessage,
  ClaimStatus,
  PaymentState,
  ReviewState,
  Claim,
  ClaimResponse,
} from 'services/claim/types';

import { useStyles } from './styles';

interface ClaimReponseProps {
  policy: Policy;
  latestMessage: IClaimMessage | null;
  onClaimStatusChange: (status: ClaimStatus | PaymentState | ReviewState) => void;
  onNumberOfPhotosUploadedChange: (isDelete?: boolean) => void;
  onClaimMessagesChange: (claimMessages: IClaimMessage[]) => void;
  updateAvailableEvents: (availableEvents: string[]) => void;
  availableEvents: string[];
}

export default function ClaimReponse({
  policy,
  latestMessage,
  onClaimStatusChange,
  onNumberOfPhotosUploadedChange,
  onClaimMessagesChange,
  updateAvailableEvents,
  availableEvents,
}: ClaimReponseProps) {
  const classes = useStyles();
  const intl = useIntl();
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const { claim } = policy;

  const [response, setResponse] = useState<ValidatedTextFieldState>({ value: '', invalid: false });
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('paypal');
  const [bankDetails, setBankDetails] = useState<ValidatedTextFieldState>({ value: '', invalid: false });
  const [paypalAccountHolder, setPaypalAccountHolder] = useState<ValidatedTextFieldState>({
    value: '',
    invalid: false,
  });
  const [paypalEmail, setPaypalEmail] = useState<ValidatedTextFieldState>({ value: '', invalid: false });
  const [errorMessage, setErrorMessage] = useState('');
  const reNotEmpty = /\S/;
  const regexEmailFormat = /^.+@.+\..+$/;

  if (!claim) return null;

  function appendPhoto(uploaded: Photo) {
    setPhotos([...photos, uploaded]);
    onNumberOfPhotosUploadedChange();
    setUploading(false);
  }

  async function removePhoto(previewIndex: number) {
    const cloned = photos.slice();

    const [removed] = cloned.splice(previewIndex, 1);
    setPhotos(cloned);

    await ClaimService.deletePhoto(policy.jwt.token, policy.shipment.id, removed.id);

    onNumberOfPhotosUploadedChange(true);
  }

  async function submitResponse() {
    const arePaymentDetailsValid =
      (paymentMethod === 'paypal' && paypalAccountHolder.value && paypalEmail.value) ||
      (paymentMethod === 'bank_account' && bankDetails.value);

    if (!claim?.id || submitting) return;
    if (!availableEvents.includes('submit_payment_info') && !response.value) return;
    if (availableEvents.includes('submit_payment_info') && !arePaymentDetailsValid) return;

    setSubmitting(true);

    const payload: ClaimResponse = {
      id: claim.id,
      event: claim.available_events[0],
      message: response.value,
    };

    if (availableEvents.includes('submit_payment_info')) {
      if (paymentMethod === 'paypal') {
        payload.claim_payment_method_attributes = {
          payment_method: paymentMethod,
          payment_details: {
            paypal_email: paypalEmail.value,
            paypal_account_holder: paypalAccountHolder.value,
          },
        };
      }

      if (paymentMethod === 'bank_account') {
        payload.claim_payment_method_attributes = {
          payment_method: paymentMethod,
          payment_details: {
            bank_details: bankDetails.value,
          },
        };
      }
    }

    ClaimService.respondClaim(policy.shipment.id, payload)
      .then(({ claim }) => {
        onClaimStatusChange(getClaimStatus(claim));
        onClaimMessagesChange(claim.claim_messages);
        updateAvailableEvents(claim.available_events);
      })
      .catch((error) => {
        error === 'Token is expired' ? setErrorMessage('expired-session') : setErrorMessage('default-error');
      })
      .finally(() => setSubmitting(false));
  }

  function getClaimStatus(claim: Claim) {
    return claim.processor === 'insureship'
      ? claim.provider_claim_status
      : claim.review_state === 'approved'
      ? claim.payment_state
      : claim.review_state;
  }

  function onPaymentOptionChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPaymentMethod(e.target.value);
  }

  return (
    <Card className={classes.root}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <Typography variant="body2" style={{ fontWeight: 700, marginBottom: 10 }}>
            <FormattedMessage
              id="filed-claim-section.claim-response.latest-message"
              defaultMessage="Latest message from Easyship"
            />
          </Typography>

          <Typography
            variant="body2"
            component="div"
            style={{ wordBreak: 'break-word', overflowY: 'scroll', maxHeight: 235 }}
          >
            {latestMessage?.content ? (
              latestMessage.content.split('\n').map((sentence: string, index: number) => (
                <p key={index} style={{ marginBottom: 10 }}>
                  {sentence}
                </p>
              ))
            ) : (
              <FormattedMessage id="global.not-available" defaultMessage="Not available" />
            )}
          </Typography>
        </Grid>

        {availableEvents.includes('submit_payment_info') && (
          <Grid item>
            <Typography variant="body2" style={{ fontWeight: 700, marginBottom: 10 }}>
              <FormattedMessage
                id="filed-claim-section.claim-response.claim-payment-option"
                defaultMessage="Select claim payment option"
              />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage
                id="filed-claim-section.claim-response.claim-payment-description"
                defaultMessage="The approved claim amount will be sent to the following payment method"
              />
            </Typography>

            <FormControl>
              <RadioGroup defaultValue="paypal" onChange={onPaymentOptionChange}>
                <FormControlLabel value="paypal" control={<Radio />} label="Send to my Paypal account" />
                {paymentMethod === 'paypal' && (
                  <Box ml={4} style={{ width: 300 }}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <ValidatedTextField
                          label={intl.formatMessage({
                            id: 'filed-claim-section.claim-response.account-holder-name',
                            defaultMessage: 'PayPal Account Holder Name (Required)',
                          })}
                          value={paypalAccountHolder.value}
                          esChange={setPaypalAccountHolder}
                          validation={reNotEmpty}
                          error={paypalAccountHolder.invalid}
                          fullWidth
                        />
                      </Grid>
                      <Grid item>
                        <ValidatedTextField
                          label={intl.formatMessage({
                            id: 'filed-claim-section.claim-response.paypal-email-address',
                            defaultMessage: 'Paypal Email Address (Required)',
                          })}
                          value={paypalEmail.value}
                          esChange={setPaypalEmail}
                          validation={reNotEmpty && regexEmailFormat}
                          error={paypalEmail.invalid}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <FormControlLabel value="bank_account" control={<Radio />} label="Send to my bank account" />
                {paymentMethod === 'bank_account' && (
                  <Box ml={4}>
                    <Typography variant="body1">
                      <FormattedMessage
                        id="filed-claim-section.claim-response.bank_account-details"
                        defaultMessage="Enter your bank account details including:"
                      />
                    </Typography>
                    <Typography variant="body1" component="div">
                      <ul>
                        <li>
                          <FormattedMessage
                            id="filed-claim-section.claim-response.account-holder-name"
                            defaultMessage="Account Holder Name"
                          />
                        </li>
                        <li>
                          <FormattedMessage
                            id="filed-claim-section.claim-response.bank-name-location"
                            defaultMessage="Bank's Name and Location"
                          />
                        </li>
                        <li>
                          <FormattedMessage
                            id="filed-claim-section.claim-response.bank_account-number"
                            defaultMessage="Bank Account Number and Branch Code"
                          />
                        </li>
                        <li>
                          <FormattedMessage
                            id="filed-claim-section.claim-response.iban-number"
                            defaultMessage="IBAN number or Swift Code (you can contact your bank for it)"
                          />
                        </li>
                      </ul>
                    </Typography>
                    <ValidatedTextField
                      value={bankDetails.value}
                      esChange={setBankDetails}
                      validation={reNotEmpty}
                      inputProps={{ maxLength: 350 }}
                      multiline
                      placeholder={intl.formatMessage({
                        id: 'global.type-message',
                        defaultMessage: 'Type your bank details here',
                      })}
                      className={classes.textField}
                      error={bankDetails.invalid}
                      fullWidth
                    />
                  </Box>
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
        )}

        <Grid item>
          <Typography variant="body2" style={{ fontWeight: 700 }}>
            <FormattedMessage
              id="filed-claim-section.claim-response.additional-info"
              defaultMessage="Additional info"
            />
          </Typography>

          <ValidatedTextField
            value={response.value}
            esChange={setResponse}
            validation={reNotEmpty}
            inputProps={{ maxLength: 350 }}
            multiline
            placeholder={intl.formatMessage({
              id: 'global.type-message',
              defaultMessage: 'Type your message here',
            })}
            className={classes.textField}
            error={response.invalid}
            fullWidth
          />
        </Grid>

        {!availableEvents.includes('submit_payment_info') && (
          <Grid item>
            <Typography variant="body2" style={{ fontWeight: 700, marginBottom: 15 }}>
              <FormattedMessage id="filed-claim-section.claim-response.file-upload" defaultMessage="File Upload" />
            </Typography>

            <Uploader
              endpoint={ClaimService.getUploadEndpoint(policy.shipment.id)}
              onSelection={() => setUploading(true)}
              onResponse={appendPhoto}
              onRemove={removePhoto}
              headers={{ 'jwt-token': policy.jwt.token }}
            />
          </Grid>
        )}

        {errorMessage === 'expired-session' && (
          <Grid item style={{ marginTop: 15 }}>
            <AlertNotification type="warning">
              <FormattedMessage
                id="global.expired-session"
                defaultMessage="This session has expired. Please refresh the page and and enter claim details to continue processing this claim."
              />
            </AlertNotification>
          </Grid>
        )}

        {errorMessage === 'default-error' && (
          <Grid item style={{ marginTop: 15 }}>
            <AlertNotification type="warning">
              <FormattedMessage
                id="global.default-error"
                defaultMessage="Something went wrong. Please refresh the page and try again."
              />
            </AlertNotification>
          </Grid>
        )}

        <Grid item>
          <Button
            color="primary"
            onClick={submitResponse}
            pending={submitting}
            disabled={uploading}
            style={{ width: isNarrow ? '100%' : 180, display: 'block', margin: '0 auto' }}
          >
            <FormattedMessage id="global.submit" defaultMessage="Submit"></FormattedMessage>
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
