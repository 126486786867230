import { Policy, ClaimType, ClaimPayload, Claim, ClaimResponse, ApiHeaders } from './types';

const API_BASE = process.env.REACT_APP_API_BASE;
export let HEADERS: ApiHeaders = { 'Content-Type': 'application/json' };

const handleError = async (res: Response) => {
  const { error } = await res.json().catch();

  throw error;
};

async function find(query: string, email: string): Promise<{ policies: Policy[] }> {
  const options = {
    method: 'GET',
    headers: HEADERS,
  };
  const res = await fetch(`${API_BASE}/v1/buyer/policies?query=${query}&email_address=${email}`, options);
  return res.ok ? res.json() : handleError(res);
}

async function findWithJwt(jwt: string): Promise<{ policies: Policy[] }> {
  const options = {
    method: 'GET',
  };
  const res = await fetch(`${API_BASE}/v1/buyer/policies?token=${jwt}`, options);
  return res.ok ? res.json() : handleError(res);
}

async function create(shipment_id: string, claim_type: ClaimType): Promise<{ claim: Claim }> {
  const options = {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify({ claim_type }),
  };
  const res = await fetch(`${API_BASE}/v1/buyer/shipments/${shipment_id}/claim`, options);
  return res.ok ? res.json() : handleError(res);
}

async function update(
  shipment_id: string,
  payload: ClaimPayload | { claim_type: ClaimType; id: string },
): Promise<{ claim: Claim }> {
  const options = {
    method: 'PATCH',
    headers: HEADERS,
    body: JSON.stringify(payload),
  };
  const res = await fetch(`${API_BASE}/v1/buyer/shipments/${shipment_id}/claim/update_claim_type`, options);
  return res.ok ? res.json() : handleError(res);
}

async function submit(
  shipment_id: string,
  payload: ClaimPayload | { claim_type: ClaimType; id: string },
): Promise<{ claim: Claim }> {
  const options = {
    method: 'PATCH',
    headers: HEADERS,
    body: JSON.stringify(payload),
  };
  const res = await fetch(`${API_BASE}/v1/buyer/shipments/${shipment_id}/claim/submit`, options);
  return res.ok ? res.json() : handleError(res);
}

async function respondClaim(shipment_id: string, payload: ClaimResponse): Promise<{ claim: Claim }> {
  const options = {
    method: 'PATCH',
    headers: HEADERS,
    body: JSON.stringify(payload),
  };
  const res = await fetch(`${API_BASE}/v1/buyer/shipments/${shipment_id}/claim/update_state`, options);
  return res.ok ? res.json() : handleError(res);
}

async function deletePhoto(jwt_token: string, shipment_id: string, photoId: string): Promise<Response> {
  return fetch(`${API_BASE}/v1/buyer/shipments/${shipment_id}/claim_photos/${photoId}`, {
    method: 'DELETE',
    headers: { 'jwt-token': jwt_token },
  });
}

const ClaimResource = { find, findWithJwt, create, update, submit, respondClaim, deletePhoto };
export default ClaimResource;
