import { useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Dropzone, { IUploadParams, IFileWithMeta, IInputProps } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { ImageList, Typography } from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';

import { ApiHeaders, Photo } from 'services/claim/types';
import useStyles from './useStyles';
import { getFilesFromEvent, handleChangeStatus, handleSubmit } from './eventHandlers';
import UploadedPhoto from './UploadedPhoto';

export interface UploaderProps {
  endpoint?: string;
  headers?: Partial<ApiHeaders>;
  photos?: Photo[];
  onSelection?: (files: File[]) => void;
  onRemove?: (previewIndex: number) => void;
  onResponse?: (photo: Photo) => void;
  reportError?: (message: string) => void;
}

const Uploader = (props: UploaderProps) => {
  const classes = useStyles();
  const [uploading, setUploading] = useState(false);
  const [previews, setPreviews] = useState<string[]>(props.photos?.map(({ url }) => url) || []);

  const getUploadParams = ({ meta }: IFileWithMeta) => {
    return { url: props.endpoint, headers: props.headers, type: meta.type } as IUploadParams;
  };

  const handleRemove = (previewIndex: number) => {
    const cloned = previews.slice();

    cloned.splice(previewIndex, 1);
    setPreviews(cloned);
    if (props.onRemove) props.onRemove(previewIndex);
  };

  const ulRef = useCallback((node) => {
    if (node !== null) node.scrollLeft = node.scrollWidth;
  }, []);

  const FileInputField = ({ accept, onFiles, files, getFilesFromEvent }: IInputProps) => {
    const paddingClass = !previews?.length ? '' : previews.length > 1 ? classes.withPhotos : classes.withOnePhoto;

    return (
      <>
        <label className={`${classes.label} ${paddingClass}`}>
          <AddAPhotoIcon className={classes.icon} />
          <Typography variant="button" display="block">
            {uploading ? (
              <FormattedMessage id="uploader.busy" defaultMessage="Uploading..." />
            ) : files.length > 0 ? (
              <FormattedMessage id="uploader.more" defaultMessage="Add more" />
            ) : (
              <FormattedMessage id="uploader.upload" defaultMessage="Upload photos" />
            )}
          </Typography>
          <input
            style={{ display: 'none' }}
            type="file"
            accept={accept}
            multiple
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              getFilesFromEvent(e).then((chosenFiles: File[]) => {
                onFiles(chosenFiles);
                if (props.onSelection) props.onSelection(chosenFiles);
                return chosenFiles;
              });
            }}
            disabled={uploading}
          />
        </label>
        <div className={classes.carousel}>
          <ImageList className={classes.ImageList} cols={2.5} ref={ulRef}>
            {previews.map((url: string, index) => (
              <UploadedPhoto url={url} onRemove={() => handleRemove(index)} disabled={uploading} key={url} />
            ))}
          </ImageList>
        </div>
      </>
    );
  };

  return (
    <Dropzone
      classNames={classes}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus(
        previews,
        setPreviews,
        setUploading,
        handleRemove,
        props.onResponse,
        props.reportError,
      )}
      onSubmit={handleSubmit}
      InputComponent={FileInputField}
      getFilesFromEvent={getFilesFromEvent}
    />
  );
};

export default Uploader;
