import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import StepTracker from 'components/StepTracker';

interface HeaderProps {
  totalSteps: number;
  currentStep: number;
  children: React.ReactNode;
}

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    flexWrap: 'nowrap',
  },
}));

export default function Header(props: HeaderProps) {
  const { totalSteps, currentStep } = props;
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between" alignItems="center" className={classes.title}>
      <Grid item>
        <Typography variant="h3" component="h1">
          {props.children}
        </Typography>
      </Grid>
      <Grid item>
        <StepTracker steps={totalSteps} currentStep={currentStep}></StepTracker>
      </Grid>
    </Grid>
  );
}
