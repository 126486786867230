import { Card, Grid, Typography, Divider, Collapse } from '@material-ui/core';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useState } from 'react';
import { useStyles } from './styles';

import ItemsList from 'components/ItemsList';
import ClaimStatusChip from 'components/ClaimStatusChip';
import ShipmentSummary from 'components/ShipmentSummary';
import OrderSummary from 'components/OrderSummary';

import { Policy, AllClaimStatuses } from 'services/claim/types';

interface ClaimSummaryProps {
  policy: Policy;
  showSuccessBanner: boolean;
  claimStatus: AllClaimStatuses;
  numberOfPhotosUploaded: number;
}

export default function ClaimSummary({
  policy,
  showSuccessBanner,
  claimStatus,
  numberOfPhotosUploaded,
}: ClaimSummaryProps) {
  const classes = useStyles();
  const { shipment_items, claim } = policy;
  const courierName = policy.courier.name;
  const mostRecentTracking = policy.trackings.reduce((latestTracking, currentTracking) => {
    return currentTracking.leg_number > latestTracking.leg_number ? currentTracking : latestTracking;
  }, policy.trackings[0]);
  const [showDetails, setShowDetails] = useState<boolean>(!showSuccessBanner);

  if (!policy || !claim) return null;

  return (
    <div className={classes.root}>
      <Card className={classes.summaryCard}>
        <OrderSummary
          orderNumber={policy.shipment.platform_order_number}
          orderDate={policy.shipment.order_created_at}
          imgUrl={policy.company.logo_url}
          companyName={policy.company.name}
        />
        <Divider />
        {(showSuccessBanner || claim.processor === 'insureship') && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            className={classes.successContainer}
          >
            {showSuccessBanner ? (
              <>
                <Grid item className={classes.label}>
                  <img src="/images/claim-summary/check.svg" alt="Success Check" />
                </Grid>
                <Grid item>
                  <Typography variant="h2">
                    <FormattedMessage
                      id="claim-summary.success-header"
                      defaultMessage="Claim filed successfully!"
                    ></FormattedMessage>
                  </Typography>
                </Grid>
                {claim.processor === 'insureship' && (
                  <Grid item className={classes.label}>
                    <Typography variant="body1" align="center">
                      <FormattedMessage
                        id="claim-summary.success-description"
                        defaultMessage="Claims are processed by our partner Insureship. They will process the claim within 5 to 7 business days."
                      ></FormattedMessage>
                    </Typography>
                  </Grid>
                )}
              </>
            ) : claim.processor === 'insureship' ? (
              <Grid item>
                <Typography variant="body1" align="center">
                  <FormattedMessage
                    id="claim-summary.success-description-alt"
                    defaultMessage="Claims are processed by our partner Insureship. They will follow up with you via email shortly."
                  ></FormattedMessage>
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        )}
        <Grid container direction="column" className={classes.claimInfoContainer}>
          <Grid item>
            <Grid container direction="row">
              <Grid item className={classes.infoTitle}>
                <Typography variant="body1">
                  <FormattedMessage
                    id="claim-summary.submission-date"
                    defaultMessage="Submission Date:"
                  ></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {claim.provider_claim_filed_date ? (
                    <FormattedDate
                      value={new Date(claim.provider_claim_filed_date)}
                      year="numeric"
                      month="short"
                      day="2-digit"
                    />
                  ) : (
                    '-'
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item className={classes.infoTitle}>
                <Typography variant="body1">
                  <FormattedMessage id="claim-summary.claim-id" defaultMessage="Claim ID:"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {claim.processor === 'insureship' ? claim.provider_claim_id : claim.easyship_claim_id || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row">
              <Grid item className={classes.infoTitle}>
                <Typography variant="body1">
                  <FormattedMessage id="claim-summary.issue-type" defaultMessage="Issue Type:"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  {claim.claim_type === 'damaged_or_missing' ? 'Damage/Missing Items' : 'Lost Items'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {claimStatus && (
            <Grid item>
              <Grid container direction="row">
                <Grid item className={classes.infoTitle}>
                  <Typography variant="body1">
                    <FormattedMessage id="claim-summary.claim-status" defaultMessage="Claim Status:"></FormattedMessage>
                  </Typography>
                </Grid>
                <Grid item>
                  <ClaimStatusChip status={claimStatus} />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Grid container direction="row">
              <Grid item className={classes.infoTitle}>
                <Typography variant="body1">
                  <FormattedMessage
                    id="claim-summary.photo-evidence"
                    defaultMessage="Photo Evidence:"
                  ></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">
                  <FormattedMessage
                    id="claim-summary.photo-count"
                    defaultMessage="{numberOfPhotos, plural, =0 {No photos} one {1 photo} other {# photos}} uploaded"
                    values={{ numberOfPhotos: numberOfPhotosUploaded }}
                  ></FormattedMessage>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Collapse in={!showDetails}>
          <Grid item onClick={() => setShowDetails(!showDetails)} className={classes.showDetails}>
            <Typography variant="h5" align="center">
              <FormattedMessage
                id="claim-summary.show-details"
                defaultMessage="See shipment details"
              ></FormattedMessage>
            </Typography>
          </Grid>
        </Collapse>
        <Collapse in={showDetails} timeout={500}>
          <div>
            <Divider />
            <ShipmentSummary policy={policy} hideItems={true} />
            <Grid container direction="row" wrap="nowrap" className={classes.shipmentDetailContainer}>
              <Grid item className={classes.label}>
                <Typography variant="body1">
                  <FormattedMessage id="claim-summary.courier" defaultMessage="Courier:"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{courierName || '-'}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" wrap="nowrap" className={classes.shipmentDetailContainer}>
              <Grid item className={classes.label}>
                <Typography variant="body1">
                  <FormattedMessage id="claim-summary.tracking" defaultMessage="Tracking:"></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{mostRecentTracking?.tracking_number || '-'}</Typography>
              </Grid>
            </Grid>
            <Grid container direction="row" className={classes.shipmentDetailContainer}>
              <Grid item className={classes.label}>
                <Typography variant="body1" className={classes.itemsAffected}>
                  <FormattedMessage
                    id="claim-summary.items-affected"
                    defaultMessage="Items Affected:"
                  ></FormattedMessage>
                </Typography>
              </Grid>
              <Grid item className={classes.itemList}>
                <ItemsList items={shipment_items} />
              </Grid>
            </Grid>
          </div>
        </Collapse>
      </Card>
    </div>
  );
}
