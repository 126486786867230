import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

import ItemsList from 'components/ItemsList';

import { Policy } from 'services/claim/types';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
    '& p': {
      margin: 0,
    },
    '& .MuiGrid-container': {
      marginBottom: 10,
    },
  },
  label: {
    minWidth: 70,
  },
}));

interface ShipmentSummaryProps {
  policy: Policy;
  hideItems?: boolean;
}

export default function ShipmentSummary(props: ShipmentSummaryProps) {
  const classes = useStyles();
  const { policy, hideItems } = props;
  const address = policy.destination_address;
  const items = policy.shipment_items;
  const formattedAddress = `${address.destination_name}, ${address.address_line_1}, ${address.city}, ${address.country.name}`;

  return (
    <div className={classes.root}>
      <Grid container direction="row" wrap="nowrap">
        <Grid item className={classes.label}>
          <Typography variant="body1">
            <FormattedMessage id="claim-summary.ship-to" defaultMessage="Ship To:"></FormattedMessage>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{formattedAddress}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" wrap="nowrap">
        <Grid item className={classes.label}>
          <Typography variant="body1">
            <FormattedMessage id="global.email" defaultMessage="Email"></FormattedMessage>:
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{address.email_address}</Typography>
        </Grid>
      </Grid>
      {!hideItems && (
        <Grid container direction="row" wrap="nowrap">
          <Grid item className={classes.label}>
            <Typography variant="body1">
              <FormattedMessage id="global.items" defaultMessage="Items:"></FormattedMessage>
            </Typography>
          </Grid>
          <Grid item>
            <ItemsList items={items} />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
