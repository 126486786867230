/** TBD: update this to match backend specs */

export type HkDistrict = {
  id?: number;
  area: string;
  district: string;
  zone: string;
};

export type Country = {
  name: string;
  alpha2: string;
};

export type Address = {
  destination_name: string;
  contact_phone?: string;
  email_address: string;
  address_line_1: string;
  address_line_2?: string;
  postal_code?: string;
  city: string;
  state?: string;
  country: Country;
  hk_district?: HkDistrict;
};

export type Courier = {
  name: string;
};

export type Box = {
  id: string;
  trackingNumber: string;
  items: ShipmentItem[];
};

export type Shipment = {
  id: string;
  platform_order_number: string;
  order_created_at: string;
  claim_filing_window_opens?: string;
  claim_filing_window_closes: string;
};

export type Jwt = {
  token: string;
  expires_at: string;
};

export type Policy = {
  shipment: Shipment;
  destination_address: Address;
  trackings: Tracking[];
  shipment_items: ShipmentItem[];
  company: Company;
  courier: Courier;
  claim?: Claim;
  jwt: Jwt;
};

export type Tracking = {
  id: string;
  leg_number: number;
  tracking_number: string;
  aftership_slug: string;
  tracking_state: string;
};

export type ShipmentItem = {
  id: string;
  description: string;
  quantity: number;
  claim_declared_customs_value: number;
  claim_declared_currency: string;
};

export type ItemSelection = {
  claim_declared_currency: string;
  claim_declared_customs_value: number;
  shipment_item_id: string;
  quantity: number;
  quantity_limit: number;
  description: string;
  sku: string;
};

export type ClaimPayloadItem = {
  shipment_item_id: string;
  quantity: number;
};

export type Company = {
  logo_url: string;
  name: string;
};

export type Claim = {
  id: string;
  claim_type: string;
  selected_shipment_items: ItemSelection[];
  user_description: string;
  photos: Photo[];
  check_certify: boolean;
  provider_claim_id: string;
  provider_claim_status: ClaimStatus;
  provider_claim_filed_date: string;
  provider_claim_portal_url: string | null;
  all_photos: Photo[];
  available_events: (ReviewAction | PaymentAction)[];
  claim_messages: IClaimMessage[];
  review_state: ReviewState; // Easyship only
  payment_state: PaymentState;
  processor: 'insureship' | 'easyship';
  easyship_claim_id: string;
};

export type Photo = {
  id: string;
  url: string;
  thumb_url: string;
};

export enum ClaimStatus {
  Submitted = 'submitted',
  Success = 'Success',
  Approved = 'Approved',
  ClaimReceived = 'Claim Received',
  Closed = 'Closed',
  ClosedDenied = 'Closed - Denied',
  ClosedPaid = 'Closed - Paid',
  Completed = 'Completed',
  Denied = 'Denied',
  Paid = 'Paid',
  PaymentInfoRequested = 'Payment Info Request',
  PendingDenial = 'Pending Denial',
  UnderReview = 'Under Review',
  WaitingOnDocuments = 'Waiting On Documents',
  esPaid = 'paid',
  esDocumentsRequested = 'documents_requested',
  esPendingReview = 'pending_review',
  esPaymentInfoRequested = 'payment_info_requested',
  esProcessingPayment = 'processing_payment',
  esDenied = 'denied',
  esClosed = 'closed',
  esApproved = 'approved',
}

export enum ClaimType {
  Lost = 'lost',
  Damaged = 'damaged_or_missing',
}

export type ClaimPayload = {
  claim_type: string;
  selected_shipment_items?: ClaimPayloadItem[];
  user_description: string;
};

export type ClaimResponse = {
  id: string; // claim ID, not shipment
  event: ReviewAction | PaymentAction;
  message: string;
  claim_payment_method_attributes?: ClaimPaymentMethod;
};

export type ClaimPaymentMethod = {
  payment_method: string;
  payment_details: {
    paypal_email?: string;
    paypal_account_holder?: string;
    bank_details?: string;
  };
};

export type IClaimMessage = {
  created_at: string;
  user_role: UserRole;
  user_first_name: string;
  user_last_name: string;
  buyer_name: string | null;
  content: string;
  claim_photos: Photo[];
};

export type UserRole = 'agent' | 'claimee' | 'system';

export type ReviewState = 'submitted' | 'pending_review' | 'documents_requested' | 'approved' | 'closed' | 'denied';

export type PaymentState = 'payment_info_requested' | 'processing_payment' | 'paid';

export type ReviewAction = 'request_documents' | 'submit_documents' | 'reopen' | 'close' | 'deny' | 'approve';
export type PaymentAction = 'submit_payment_info' | 'mark_as_payment_info_requested' | 'mark_as_paid';

export type AllClaimStatuses = ClaimStatus | PaymentState | ReviewState | '';

export type PaymentMethod = 'paypal' | 'bank_account';

export type ApiHeaders = {
  'jwt-token'?: string;
  'recaptcha-token'?: string;
  'Content-Type': string;
};
