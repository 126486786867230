import { Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    margin: '5px 0 !important',
    '& p': {
      margin: 0,
    },
    '& .MuiGrid-container': {
      marginBottom: 15,
    },
    position: 'relative',
  },
  detailsContainer: {
    width: 245,
    paddingTop: 5,
  },
  logo: {
    height: 60,
    width: 60,
    marginRight: 15,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  companyName: {
    maxHeight: '100%',
    fontWeight: 700,
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
  labels: {
    marginBottom: '5px !important',
  },
  title: {
    width: 80,
  },
  content: {
    lineBreak: 'normal',
    width: 165,
  },
}));

interface OrderSummaryProps {
  orderNumber: string;
  orderDate: string;
  imgUrl?: string;
  companyName: string;
}

export default function OrderSummary(props: OrderSummaryProps) {
  const classes = useStyles();
  const { orderNumber, orderDate, imgUrl, companyName } = props;

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item className={classes.logo} style={{ backgroundImage: `url(${imgUrl})` }} title={companyName}>
        {!imgUrl && (
          <Typography variant="body1" className={classes.companyName}>
            {companyName}
          </Typography>
        )}
      </Grid>
      <Grid item className={classes.detailsContainer}>
        <Grid container direction="row" className={classes.labels}>
          <Typography variant="body1" className={classes.title}>
            <FormattedMessage id="claim-summary.order-number" defaultMessage="Order No.:"></FormattedMessage>
          </Typography>
          <Typography variant="body2" className={classes.content}>
            {orderNumber || '-'}
          </Typography>
        </Grid>
        <Grid container direction="row" className={classes.labels}>
          <Typography variant="body1" className={classes.title}>
            <FormattedMessage id="claim-summary.order-date" defaultMessage="Order Date:"></FormattedMessage>
          </Typography>
          <Typography variant="body2" className={classes.content}>
            {orderDate
              ? new Intl.DateTimeFormat('en', { month: 'short', day: '2-digit', year: 'numeric' }).format(
                  new Date(orderDate),
                )
              : '-'}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
