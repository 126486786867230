import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '25px 20px',
    minWidth: 400,
    maxWidth: 500,
    '& p': {
      margin: 0,
    },
    '& .MuiAlert-root': {
      marginTop: 10,
    },
  },
  lostHeader: {
    maxWidth: 160,
  },
  summaryCard: {
    padding: '15px 20px 5px',
    borderRadius: 8,
    boxShadow: 'none',
    opacity: 1,
    marginTop: 10,
  },
  buttonsContainer: {
    margin: '15px 0 20px',
  },
  textField: {
    width: '100%',

    '& .MuiInputBase-root': {
      alignItems: 'start',
      marginTop: '20px',
    },

    '& .MuiOutlinedInput-multiline': {
      padding: 10,
      height: 80,
    },

    '& .MuiInputAdornment-root': {
      position: 'relative',
      top: 8,
    },

    '& .MuiInputBase-input': {
      height: '100% !important',
      overflow: 'auto !important',
    },
  },
  note: {
    margin: '10px 0 25px',
  },
}));
