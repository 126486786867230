import { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Card, Container, Divider, Grid, Typography } from '@material-ui/core';

import OrderSummary from 'components/OrderSummary';
import ShipmentSummary from 'components/ShipmentSummary';
import PopButton from 'components/PopButton';
import Header from 'components/Header';
import FooterLinks from 'components/FooterLinks';
import AlertNotification from 'components/AlertNotification/index';

import { Policy, ClaimType } from 'services/claim/types';
import { useStyles } from './styles';

import ClaimService from 'services/claim';
import { isFuture, isPast, daysFromToday } from 'services/date';
import { HEADERS } from 'services/claim/resource';

interface IClaimScreenState {
  policy?: Policy;
}

export default function ClaimScreen() {
  const history = useHistory();
  const classes = useStyles();
  const { policy } = (useLocation().state || {}) as IClaimScreenState;
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (policy) {
      HEADERS['jwt-token'] = policy.jwt.token;
    }
  }, [policy]);

  if (!policy) {
    history.push('/');
    return null;
  }

  function validateFilingWindow(claimType: ClaimType) {
    if (!policy) return;
    const { claim_filing_window_opens, claim_filing_window_closes } = policy.shipment;

    if (claimType === ClaimType.Lost && claim_filing_window_opens && isFuture(claim_filing_window_opens)) {
      const daysUntilWindowOpens = daysFromToday(claim_filing_window_opens);
      return history.push('/claim/not-eligible', {
        beforeFilingWindow: true,
        daysUntilWindowOpens,
        claim_filing_window_opens,
      });
    } else if (isPast(claim_filing_window_closes)) {
      return history.push('/claim/not-eligible', { pastFilingWindow: true });
    } else {
      createOrUpdateClaim(claimType);
    }
  }

  function createOrUpdateClaim(claim_type: ClaimType) {
    if (!policy) return;

    const promise = policy.claim?.id
      ? ClaimService.update(policy.shipment.id, { claim_type, id: policy.claim.id })
      : ClaimService.create(policy.shipment.id, claim_type);

    promise
      .then(({ claim }) => {
        history.push(claim_type === ClaimType.Lost ? '/claim/lost' : '/claim/damaged', {
          policy: { ...policy, claim },
        });
      })
      .catch((error) => {
        error === 'Token is expired' ? setErrorMessage('expired-session') : setErrorMessage('default-error');
      });
  }

  return (
    <Container className={classes.root}>
      <Header totalSteps={3} currentStep={2}>
        <FormattedMessage id="home.headline" defaultMessage="Resolve an Issue" />
      </Header>

      <Grid item>
        {policy && (
          <Card className={classes.summaryCard}>
            <OrderSummary
              orderNumber={policy.shipment.platform_order_number}
              orderDate={policy.shipment.order_created_at}
              imgUrl={policy.company.logo_url}
              companyName={policy.company.name}
            />
            <Divider />
            <ShipmentSummary policy={policy} />
          </Card>
        )}
      </Grid>

      {errorMessage === 'expired-session' && (
        <Grid item style={{ marginTop: 15 }}>
          <AlertNotification type="warning">
            <FormattedMessage
              id="global.expired-session"
              defaultMessage="This session has expired. Please refresh the page and and enter claim details to continue processing this claim."
            />
          </AlertNotification>
        </Grid>
      )}

      {errorMessage === 'default-error' && (
        <Grid item style={{ marginTop: 15 }}>
          <AlertNotification type="warning">
            <FormattedMessage
              id="global.default-error"
              defaultMessage="Something went wrong. Please refresh the page and try again."
            />
          </AlertNotification>
        </Grid>
      )}

      <div className={classes.subheader}>
        <Typography variant="h3" component="h1" align="center">
          <FormattedMessage id="claim.subheader" defaultMessage="What went wrong with the shipment?" />
        </Typography>
      </div>

      <Grid container justifyContent="space-between">
        <Grid item className={classes.PopButtonWrapper}>
          <PopButton imgPath="images/claim/damaged-box.svg" handleClick={() => createOrUpdateClaim(ClaimType.Damaged)}>
            <Typography variant="h3" component="h1" align="center">
              <FormattedMessage id="claim.damage" defaultMessage="Damaged / Missing Items" />
            </Typography>
          </PopButton>
        </Grid>
        <Grid item className={classes.PopButtonWrapper}>
          <PopButton imgPath="images/claim/lost-box.svg" handleClick={() => validateFilingWindow(ClaimType.Lost)}>
            <Typography variant="h3" component="h1" align="center">
              <FormattedMessage id="claim.lost" defaultMessage="Never Delivered" />
            </Typography>
          </PopButton>
        </Grid>
      </Grid>

      <FooterLinks companyName={policy.company.name}></FooterLinks>
    </Container>
  );
}
