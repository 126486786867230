import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ShipmentItemSummary from 'components/ShipmentItemSummary';
import Incrementor from 'components/Incrementor';
import { ShipmentItem } from 'services/claim/types';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
  },
  incrementor: {
    position: 'relative',
    left: 5,
  },
}));

interface IItemWithIncrementorProps {
  item: ShipmentItem;
  claimCount: number;
  onInputChange: (value: number) => void;
  className?: string;
  error?: boolean;
}

export default function ItemWithIncrementor({ item, claimCount, onInputChange, error }: IItemWithIncrementorProps) {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.root}>
      <Grid item>
        <ShipmentItemSummary item={item} />
      </Grid>
      <Grid item className={classes.incrementor}>
        <Incrementor value={claimCount} maxValue={item.quantity} onInputChange={onInputChange} error={error} />
      </Grid>
    </Grid>
  );
}
