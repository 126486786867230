import { createTheme } from '@material-ui/core/styles';

import COLORS from './colors';

const placeholderVisible = {
  opacity: '1 !important',
};

export const theme = createTheme({
  typography: {
    fontFamily: 'Karla',
    h1: {
      fontSize: 20,
      color: COLORS.inkDarkest,
      fontWeight: 700,
    },
    h2: {
      fontSize: 20,
      color: COLORS.inkDarkest,
    },
    h3: {
      fontSize: 16,
      color: COLORS.inkDarkest,
      fontWeight: 700,
    },
    h4: {
      fontSize: 15,
      color: COLORS.inkDarkest,
    },
    h5: {
      fontSize: 13,
      color: COLORS.inkNormal,
      fontWeight: 700,
    },
    body1: {
      fontSize: 13,
      color: COLORS.inkNormal,
    },
    body2: {
      fontSize: 13,
      color: COLORS.inkDark,
    },
    subtitle1: {
      fontSize: 16,
      color: COLORS.inkNormal,
    },
    caption: {
      fontSize: 13,
      color: COLORS.inkLight,
      fontStyle: 'italic',
    },
  },
  palette: {
    background: { default: COLORS.skyNormal },
    primary: {
      main: COLORS.greenNormal,
      contrastText: COLORS.white,
    },
    secondary: {
      main: COLORS.blueNormal,
      contrastText: COLORS.white,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          minHeight: '100%',
          marginBottom: '100px',
        },
      },
    },

    MuiOutlinedInput: {
      root: {
        marginTop: 22,
        backgroundColor: 'white',

        '&, &.Mui-focused, &:hover': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: COLORS.skyDark,
            borderWidth: '1px !important',
          },
        },

        /** This matches the `disabled` style in easyship-dashboard, but it doesn't work well without a white background */
        '&.Mui-disabled': {
          color: COLORS.inkLight,
          backgroundColor: COLORS.skyLight,
        },

        '&.Mui-error, &.Mui-error:hover': {
          borderColor: `${COLORS.redDark} !important`,
          backgroundColor: COLORS.redLight,

          '& fieldset': {
            borderColor: COLORS.redDark,
          },
        },
      },

      input: {
        padding: 10,
        color: COLORS.inkDark,

        // NOT WORKING. Need to use InputLabelProps={{ shrink: true }}
        'label[data-shrink=false] + $formControl &': {
          '&::placeholder': placeholderVisible,
          '&::-webkit-input-placeholder': placeholderVisible,
          '&::-moz-placeholder': placeholderVisible, // Firefox 19+
          '&:-ms-input-placeholder': placeholderVisible, // IE11
          '&::-ms-input-placeholder': placeholderVisible, // Edge
        },
      },

      notchedOutline: {
        '& legend': {
          width: 0,
        },
      },
    },

    MuiInputAdornment: {
      root: {
        backgroundColor: 'inherit',
      },
    },

    MuiInputLabel: {
      outlined: {
        '&, &.MuiInputLabel-shrink': {
          transform: 'translate(0px, 0px)',
        },
      },
    },

    MuiFormLabel: {
      root: {
        '&, &$disabled, &$focused': {
          color: COLORS.inkDark,
        },

        '&$error': {
          color: COLORS.inkDark,
        },

        '&$disabled': {
          color: `${COLORS.inkDark} !important`,
        },
      },
    },

    MuiSvgIcon: {
      colorError: {
        color: COLORS.redDark,
      },
    },

    MuiButton: {
      root: {
        borderRadius: 4,
        fontWeight: 'bold',
        width: 140,
        height: 40,
        boxShadow: '0px 2px 4px #284E6633',

        '&.MuiButton-textPrimary': {
          backgroundImage: `linear-gradient(-180deg, ${COLORS.greenNormal} 0%, ${COLORS.greenDark} 100%)`,
          color: COLORS.white,
        },

        '&.MuiButton-textSecondary': {
          background: `transparent linear-gradient(180deg, ${COLORS.white} 0%, ${COLORS.skyNormal} 100%) 0% 0% no-repeat padding-box`,
          color: COLORS.black,
        },
      },
      text: {
        padding: '8px 16px',

        '&$disabled': {
          backgroundColor: COLORS.skyDark,
          backgroundImage: 'none',
          color: COLORS.white,
          boxShadow: 'none',
        },
      },
    },

    MuiStepper: {
      root: {
        backgroundColor: 'inherit',
        width: 150,
        padding: '15px 0',
        '& .MuiStepIcon-root': {
          color: `${COLORS.skyDarkest} !important`,
          fontSize: 23,
        },
        '& .MuiStepIcon-active': {
          color: `${COLORS.tealNormal} !important`,
        },
        '& .MuiStepConnector-line': {
          borderColor: COLORS.skyDarkest,
        },
        '& .MuiStepLabel-root': {
          width: 23,
        },
        '& .MuiStep-horizontal:last-child': {
          padding: '0 0 0 8px',
        },
      },
    },

    MuiDivider: {
      root: {
        margin: '0 -20px',
        backgroundColor: COLORS.skyNormal,
      },
    },
  },
});
