export function daysFromToday(futureDate: string): number {
  const millisecondsInOneDay = 24 * 60 * 60 * 1000;

  return Math.floor(Math.abs(new Date(futureDate).valueOf() - Date.now()) / millisecondsInOneDay);
}

export function isPast(date: string): boolean {
  return new Date(date).valueOf() < Date.now();
}

export function isFuture(date: string): boolean {
  return new Date(date).valueOf() > Date.now();
}

export default { daysFromToday, isPast, isFuture };
