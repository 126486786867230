import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { Typography, ListItem, ListItemAvatar, Box, ImageList, ImageListItem, Grid } from '@material-ui/core';

import { FormattedDate, FormattedMessage } from 'react-intl';

import COLORS from 'config/colors';

import { IClaimMessage } from 'services/claim/types';

interface ClaimMessageProps {
  message: IClaimMessage;
}

export default function ClaimMessage({ message }: ClaimMessageProps) {
  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('xs'));
  const { created_at, user_role, content, claim_photos } = message;

  return (
    <ListItem
      alignItems="flex-start"
      disableGutters
      style={{ display: isNarrow ? 'block' : 'flex', marginBottom: isNarrow ? 5 : 0 }}
    >
      <ListItemAvatar style={{ margin: isNarrow ? '0 0 5px' : 0, minWidth: isNarrow ? 0 : 160 }}>
        <Box textAlign={isNarrow ? 'left' : 'right'} p={isNarrow ? 0 : '0 16px 0 8px'}>
          <Grid container direction={isNarrow ? 'row' : 'column'}>
            <Grid item style={{ width: isNarrow ? 150 : 'auto' }}>
              <Typography variant="body2" style={{ color: COLORS.inkLight }}>
                <FormattedDate value={created_at} dateStyle="medium" timeStyle="short"></FormattedDate>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ color: COLORS.blueDark, fontWeight: 700 }}>
                {user_role === 'system' && (
                  <FormattedMessage id="filed-claim-section.claim-history.system" defaultMessage="System" />
                )}

                {user_role === 'agent' && (
                  <FormattedMessage id="filed-claim-section.claim-history.agent" defaultMessage="Agent" />
                )}

                {user_role === 'claimee' && (
                  <FormattedMessage id="filed-claim-section.claim-history.claimee" defaultMessage="You" />
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </ListItemAvatar>
      <div style={{ width: 263 }}>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ maxWidth: '100%', overflowX: 'auto', color: COLORS.inkDarkest }}
        >
          {content.split('\n').map((sentence: string, index: number) => (
            <p key={index} style={{ marginBottom: 5 }}>
              {sentence}
            </p>
          ))}
        </Typography>
        <ImageList rowHeight={60} gap={5} cols={3}>
          {claim_photos.map((photo) => (
            <ImageListItem key={photo.id}>
              <a href={photo.url} target="_blank" rel="noreferrer">
                <img src={photo.thumb_url} alt="thumbnail" />
              </a>
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </ListItem>
  );
}
