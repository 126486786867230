import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { ImageListItem, ImageListItemBar, IconButton } from '@material-ui/core';

import COLORS from 'config/colors';
import CloseIcon from './CloseIcon';

export const useStyles = makeStyles(() => ({
  root: {
    height: 80,
    margin: '10px 0 0 10px',
  },
  img: {
    height: '100%',
    width: 'auto',
  },
  titleBar: {
    background: 'transparent',
  },
  button: {
    marginTop: -15,
    padding: 5,
    color: COLORS.inkDark,
    '&:hover': { background: 'transparent' },
  },
}));

interface UploadedPhotoProps {
  url: string;
  disabled: boolean;
  onRemove: (url: string) => void;
}

const UploadedPhoto = (props: UploadedPhotoProps) => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ImageListItem className={classes.root}>
      <img src={props.url} alt="uploaded" className={classes.img} />
      <ImageListItemBar
        titlePosition="top"
        className={classes.titleBar}
        actionIcon={
          <IconButton
            aria-label={intl.formatMessage({ id: 'uploader.remove', defaultMessage: 'remove photo' })}
            className={classes.button}
            onClick={() => props.onRemove(props.url)}
            disabled={props.disabled}
          >
            <CloseIcon />
          </IconButton>
        }
      />
    </ImageListItem>
  );
};

export default UploadedPhoto;
