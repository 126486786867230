import { defineMessages, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Chip, Avatar, Typography } from '@material-ui/core';

import COLORS from 'config/colors';
import { ClaimStatus, ReviewState, PaymentState } from 'services/claim/types';

const messages = defineMessages({
  [ClaimStatus.Success]: {
    id: 'status.success',
    defaultMessage: 'Success',
  },
  [ClaimStatus.Submitted]: {
    id: 'status.submitted',
    defaultMessage: 'Submitted',
  },
  [ClaimStatus.Approved]: {
    id: 'status.approved',
    defaultMessage: 'Approved',
  },
  [ClaimStatus.ClaimReceived]: {
    id: 'status.claim-received',
    defaultMessage: 'Claim Received',
  },
  [ClaimStatus.Closed]: {
    id: 'status.closed',
    defaultMessage: 'Closed',
  },
  [ClaimStatus.ClosedDenied]: {
    id: 'status.closed-denied',
    defaultMessage: 'Closed - Denied',
  },
  [ClaimStatus.ClosedPaid]: {
    id: 'status.closed-paid',
    defaultMessage: 'Closed - Paid',
  },
  [ClaimStatus.Completed]: {
    id: 'status.completed',
    defaultMessage: 'Completed',
  },
  [ClaimStatus.Denied]: {
    id: 'status.denied',
    defaultMessage: 'Denied',
  },
  [ClaimStatus.Paid]: {
    id: 'status.paid',
    defaultMessage: 'Paid',
  },
  [ClaimStatus.PaymentInfoRequested]: {
    id: 'status.payment-info-requested',
    defaultMessage: 'Payment Info Request',
  },
  [ClaimStatus.PendingDenial]: {
    id: 'status.pending-denial',
    defaultMessage: 'Pending Denial',
  },
  [ClaimStatus.UnderReview]: {
    id: 'status.under-review',
    defaultMessage: 'Under Review',
  },
  [ClaimStatus.WaitingOnDocuments]: {
    id: 'status.waiting-on-documents',
    defaultMessage: 'Waiting On Documents',
  },
  [ClaimStatus.esPaid]: {
    id: 'status.paid',
    defaultMessage: 'Paid',
  },
  [ClaimStatus.esDocumentsRequested]: {
    id: 'status.documents-requested',
    defaultMessage: 'Documents Requested',
  },
  [ClaimStatus.esPendingReview]: {
    id: 'status.under-review',
    defaultMessage: 'Under Review',
  },
  [ClaimStatus.esPaymentInfoRequested]: {
    id: 'status.payment-info-requested',
    defaultMessage: 'Payment Info Request',
  },
  [ClaimStatus.esProcessingPayment]: {
    id: 'status.processing-payment',
    defaultMessage: 'Processing Payment',
  },
  [ClaimStatus.esDenied]: {
    id: 'status.denied',
    defaultMessage: 'Denied',
  },
  [ClaimStatus.esClosed]: {
    id: 'status.closed',
    defaultMessage: 'Closed',
  },
  [ClaimStatus.esApproved]: {
    id: 'status.approved',
    defaultMessage: 'Approved',
  },
});

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    alignItems: 'center',

    '& .MuiChip-avatar': {
      width: 12,
      height: 12,
      marginTop: 1,
    },

    '&.success': {
      '& .MuiChip-avatar': {
        backgroundColor: COLORS.success,
      },
    },

    '&.failure': {
      '& .MuiChip-avatar': {
        backgroundColor: COLORS.failure,
      },
    },

    '&.waiting': {
      '& .MuiChip-avatar': {
        backgroundColor: COLORS.waiting,
      },
    },

    '&.closed': {
      '& .MuiChip-avatar': {
        backgroundColor: COLORS.closed,
      },
    },
  },
  date: {
    marginTop: -4,
  },
}));

interface IClaimStatusChip {
  status: ClaimStatus | ReviewState | PaymentState;
}

export default function ClaimStatusChip({ status }: IClaimStatusChip) {
  const classes = useStyles();

  const statusColorClass = function () {
    switch (status) {
      case ClaimStatus.Success:
      case ClaimStatus.Submitted:
      case ClaimStatus.Approved:
      case ClaimStatus.ClaimReceived:
      case ClaimStatus.Completed:
      case ClaimStatus.Paid:
      case ClaimStatus.esPaid:
        return 'success';

      case ClaimStatus.PaymentInfoRequested:
      case ClaimStatus.PendingDenial:
      case ClaimStatus.UnderReview:
      case ClaimStatus.WaitingOnDocuments:
      case ClaimStatus.esDocumentsRequested:
      case ClaimStatus.esPendingReview:
      case ClaimStatus.esPaymentInfoRequested:
      case ClaimStatus.esProcessingPayment:
        return 'waiting';

      case ClaimStatus.Denied:
      case ClaimStatus.esDenied:
        return 'failure';

      case ClaimStatus.Closed:
      case ClaimStatus.ClosedDenied:
      case ClaimStatus.ClosedPaid:
      case ClaimStatus.esClosed:
        return 'closed';
    }
  };

  return messages[status] ? (
    <Chip
      variant="outlined"
      size="small"
      avatar={<Avatar>&nbsp;</Avatar>}
      className={`${classes.root} ${statusColorClass()}`}
      label={
        <Typography variant="body1">
          <FormattedMessage {...messages[status]}></FormattedMessage>
        </Typography>
      }
    />
  ) : (
    <Typography variant="body1">
      <FormattedMessage id="global.not-available" defaultMessage="Not available"></FormattedMessage>
    </Typography>
  );
}
