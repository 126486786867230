import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Grid, Container, Typography, Fade } from '@material-ui/core';
import COLORS from 'config/colors';
import HelpIcon from '@material-ui/icons/Help';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'inherit',
    height: 30,
    padding: '15px 0',
    '& .MuiDivider-root': {
      margin: '0 10px',
      height: 10,
      backgroundColor: COLORS.inkLight,
    },
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    color: COLORS.blueNormal,
    backgroundColor: 'inherit',
    marginRight: 5,
  },
  noticeContainer: {
    paddingBottom: 100,
  },
  otherIssuesNotice: {
    padding: 15,
    backgroundColor: COLORS.white,
    borderRadius: 8,
    marginTop: 20,
  },
  notice: {
    marginBottom: 15,
  },
  link: {
    color: COLORS.blueNormal,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

interface FooterLinksProps {
  companyName: string;
}

export default function FooterLinks(props: FooterLinksProps) {
  const classes = useStyles();
  const { companyName } = props;
  const [noticeVisible, setNoticeVisible] = useState(false);

  return (
    <Container className={classes.root}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item className={classes.icon}>
          <HelpIcon fontSize="small" />
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            component="span"
            align="center"
            onClick={() => setNoticeVisible(!noticeVisible)}
            className={classes.link}
          >
            <FormattedMessage id="footer.other-notice" defaultMessage="Other Issues" />
          </Typography>
        </Grid>
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            component="a"
            align="center"
            href="https://www.easyship.com/legal/insurance-terms-and-conditions"
            target="_blank"
            className={classes.link}
          >
            <FormattedMessage id="footer.terms" defaultMessage="Terms & Policy" />
          </Typography>
        </Grid>
      </Grid>

      {noticeVisible && (
        <Fade in={true} timeout={{ enter: 500 }}>
          <div className={classes.noticeContainer}>
            <Container className={classes.otherIssuesNotice}>
              <Typography variant="body1" className={classes.notice}>
                <FormattedMessage
                  id="footer.coverage"
                  defaultMessage="Your shipping insurance includes protection against lost, theft, or damage during shipping."
                />
              </Typography>
              <Typography variant="body1">
                <FormattedMessage
                  id="footer.other-issues"
                  defaultMessage="For other issues that fall outside of the shipping insurance coverage, please contact {companyName}."
                  values={{ companyName }}
                />
              </Typography>
            </Container>
          </div>
        </Fade>
      )}
    </Container>
  );
}
