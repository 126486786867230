import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';

import EasyshipHeader from 'components/EasyshipHeader';
import ShipmentForm from './ShipmentForm';
import { Policy } from 'services/claim/types';

import RecaptchaService from 'services/recaptcha';
import ClaimService from 'services/claim';
import { isPast } from 'services/date';

import { useStyles } from './styles';

export default function HomeScreen() {
  const classes = useStyles();
  const history = useHistory();
  const params = new URLSearchParams(useLocation().search);
  const jwt = params.get('token');
  const email = params.get('email');
  const query = params.get('track');

  useEffect(() => {
    if (jwt) {
      ClaimService.findWithJwt(jwt).then(({ policies }) => {
        routeToNextPage(policies);
      });
    }

    if (email && query) {
      ClaimService.find(query, email).then(({ policies }) => {
        routeToNextPage(policies);
      });
    }
  }, []);

  function routeToNextPage(policies: Policy[]) {
    if (policies.length > 1) {
      return policies.every(({ shipment }) => isPast(shipment.claim_filing_window_closes))
        ? history.push('/claim/not-eligible', { pastFilingWindow: true })
        : history.push('/claim/select', { policies });
    }

    if (policies[0].claim?.check_certify) return history.push('/claim/claim-summary', { policy: policies[0] });

    isPast(policies[0].shipment.claim_filing_window_closes)
      ? history.push('/claim/not-eligible', { pastFilingWindow: true })
      : history.push('/claim', { policy: policies[0] });
  }

  RecaptchaService.init();

  return (
    <Grid container direction="column" alignItems="center" spacing={2} className={classes.mainContainer}>
      <Grid item className={classes.header}>
        <EasyshipHeader>
          <FormattedMessage id="home.headline" defaultMessage="Resolve an Issue" />
        </EasyshipHeader>
      </Grid>

      <Grid item>
        <img className={classes.boxes} src="/images/home/boxes.png" alt="missing or damaged shipments"></img>
      </Grid>

      <Grid item className={classes.subtitle}>
        <Typography variant="subtitle1" align="center">
          <FormattedMessage
            id="home.apology1"
            defaultMessage="We're sorry to hear that you've encountered an issue. Let's start by looking up your package details."
          />
        </Typography>
      </Grid>

      <Grid container justifyContent="center" className={classes.formContainer}>
        <ShipmentForm />
      </Grid>
    </Grid>
  );
}
