import { makeStyles } from '@material-ui/core/styles';
import { Grid, InputBase, IconButton } from '@material-ui/core';
import COLORS from 'config/colors';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'inherit',
    height: 30,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: COLORS.inkFaded,
    width: 30,
    height: 40,

    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  input: {
    height: 40,
    width: 35,
    fontSize: 13,
    padding: '10px 5px 10px 12px',
    backgroundColor: COLORS.white,
    border: '1px solid #ced4da',
    borderRadius: 4,

    '&.Mui-error, &.Mui-error:hover': {
      borderColor: `${COLORS.redDark} !important`,
      backgroundColor: COLORS.redLight,
    },
  },
}));

interface IncrementorProps {
  value: number;
  minValue?: number;
  maxValue?: number;
  onInputChange: (value: number) => void;
  error?: boolean;
}

export default function Incrementor(props: IncrementorProps) {
  const classes = useStyles();
  const { value = 0, minValue = 0, maxValue = Infinity, onInputChange, error } = props;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const parsed = Number(event.target.value);
    const value = Number.isInteger(parsed) ? parsed : 0;

    onInputChange(Math.max(Math.min(value, maxValue), minValue));
  }

  function handleDecrementClick(): void {
    if (value > minValue) {
      onInputChange(value - 1);
    }
  }

  function handleIncrementClick(): void {
    if (value < maxValue) {
      onInputChange(value + 1);
    }
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item>
        <IconButton
          className={classes.icon}
          aria-label="decrement"
          onClick={handleDecrementClick}
          disabled={value === minValue}
        >
          <RemoveIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <InputBase value={value} onChange={handleChange} className={classes.input} error={error}></InputBase>
      </Grid>
      <Grid item>
        <IconButton
          className={classes.icon}
          aria-label="increment"
          onClick={handleIncrementClick}
          disabled={value === maxValue}
        >
          <AddIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
