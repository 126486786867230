import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (process.env.REACT_APP_SENTRY_DSN && process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    allowUrls: [/https:\/\/claims-?[\w\d]*\.easyship\.com/],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}
