import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';

interface PopButtonProps {
  imgPath: string;
  altText?: string;
  handleClick(): any;
  children: React.ReactNode;
}

export default function PopButton(props: PopButtonProps) {
  const useStyles = makeStyles(() => ({
    root: {
      position: 'relative',
      height: '100%',
      width: '100%',
      padding: '30px 20px',
      boxShadow: '0px 2px 15px #284E661A',
      borderRadius: 8,
      transition: 'top ease 0.5s',
      '&:hover': {
        bottom: 2,
        cursor: 'pointer',
      },
    },
    image: {
      marginBottom: 15,
      boxShadow: '0px 30px 40px -25px rgba(0, 0, 0, 1)',
    },
  }));

  const classes = useStyles();
  const { imgPath, altText = 'icon', handleClick } = props;

  return (
    <Card className={classes.root} onClick={handleClick}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item>
          <img src={imgPath} className={classes.image} alt={altText} />
        </Grid>
      </Grid>
      {props.children}
    </Card>
  );
}
