import { Checkbox, FormControl, FormGroup, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from 'config/colors';
import { ReactComponent as CheckedIcon } from './checkmark.svg';

import UncheckedIcon from './unchecked';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 10,
    width: '100%',

    '& .MuiFormControlLabel-root': {
      alignItems: 'center',
      marginRight: 0,
    },

    '& .MuiFormControlLabel-label': {
      color: COLORS.inkDarkest,
    },
  },
  iconContainer: {
    height: 24,
  },
}));

interface CheckboxProps {
  checked: boolean;
  label: string;
  error?: boolean;
  disabled?: boolean;
  onChange: () => void;
}

export default function EasyshipCheckbox(props: CheckboxProps) {
  const { checked, label, error, disabled, onChange } = props;
  const classes = useStyles();

  return (
    <FormControl required error={error} component="fieldset" className={classes.root}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              icon={<UncheckedIcon error={error} />}
              checkedIcon={<CheckedIcon />}
              disabled={disabled}
            />
          }
          label={label}
          onChange={onChange}
        />
      </FormGroup>
    </FormControl>
  );
}
