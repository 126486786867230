import { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router-dom';
import { Container, Grid, Divider, Card, Typography, Collapse } from '@material-ui/core';

import Header from 'components/Header';
import OrderSummary from 'components/OrderSummary';
import ShipmentSummary from 'components/ShipmentSummary';
import ValidatedTextField from 'components/ValidatedTextField';
import Uploader from 'components/Uploader';
import EasyshipCheckbox from 'components/EasyshipCheckbox';
import Button from 'components/Button';
import FooterLinks from 'components/FooterLinks';
import AlertNotification from 'components/AlertNotification';

import ClaimService from 'services/claim';
import { Policy, ShipmentItem, ClaimPayloadItem, ClaimType, Photo } from 'services/claim/types';

import ItemWithIncrementor from './ItemWithIncrementor';
import { useStyles } from './styles';

const reNotEmpty = /\S/;

interface IDamagedScreenState {
  policy?: Policy;
}

export default function DamagedScreen() {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const { policy } = (useLocation().state || {}) as IDamagedScreenState;

  const [claimItems, setClaimItems] = useState<ClaimPayloadItem[]>(
    (policy?.shipment_items || []).map((item) => {
      return { shipment_item_id: item.id, quantity: 0 };
    }),
  );
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [checkCertify, setCheckCertify] = useState(false);
  const [showingValidationErrors, setShowingValidationErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (!policy) {
    history.push('/');
    return null;
  }

  function appendPhoto(uploaded: Photo) {
    setPhotos([...photos, uploaded]);
    setUploading(false);
  }

  async function removePhoto(previewIndex: number) {
    const cloned = photos.slice();

    const [removed] = cloned.splice(previewIndex, 1);
    setPhotos(cloned);

    if (policy) {
      await ClaimService.deletePhoto(policy.jwt.token, policy.shipment.id, removed.id);
    }
  }

  function onIncrementorInputChange(index: number, value: number): void {
    const clone = claimItems.slice();

    clone[index].quantity = value;
    setClaimItems(clone);
  }

  function handleBackClick() {
    history.push('/claim', { policy });
  }

  function handleNextClick() {
    if (submitting) return;

    if (!policy || !description || !checkCertify || !atleastOneItemSelected()) {
      setShowingValidationErrors(true);
      return;
    }

    setSubmitting(true);

    const payload = {
      id: policy?.claim?.id,
      claim_type: ClaimType.Damaged,
      selected_shipment_items: claimItems.filter((item) => item.quantity > 0),
      photos,
      user_description: description,
      check_certify: true,
    };

    ClaimService.submit(policy.shipment.id, payload)
      .then(({ claim }) => {
        history.push('/claim/claim-summary', { policy: { ...policy, claim }, showSuccessBanner: true });
      })
      .catch((error) => {
        error === 'Token is expired' ? setErrorMessage('expired-session') : setErrorMessage('default-error');
      })
      .finally(() => setSubmitting(false));
  }

  function atleastOneItemSelected() {
    return claimItems.some((item) => {
      return item.quantity > 0;
    });
  }

  return (
    <Container className={classes.root}>
      <Header totalSteps={3} currentStep={3}>
        <FormattedMessage id="damaged.headline" defaultMessage="Claim for Damaged Shipment" />
      </Header>

      <Card className={classes.summaryCard}>
        <OrderSummary
          orderNumber={policy.shipment.platform_order_number}
          orderDate={policy.shipment.order_created_at}
          imgUrl={policy.company.logo_url}
          companyName={policy.company.name}
        />
        <Divider />
        <ShipmentSummary policy={policy} hideItems={true} />
      </Card>

      <Card className={classes.summaryCard}>
        <Typography variant="body2" component="h1" className={classes.quantities}>
          <FormattedMessage id="damaged.quantities" defaultMessage="Please input quantities of items affected." />
        </Typography>

        {policy.shipment_items.map((item: ShipmentItem, index: number) => (
          <ItemWithIncrementor
            key={item.id}
            item={item}
            claimCount={claimItems[index].quantity}
            onInputChange={(value) => onIncrementorInputChange(index, value)}
            error={!atleastOneItemSelected() && showingValidationErrors}
          />
        ))}

        <Divider />

        <ValidatedTextField
          value={description}
          esChange={({ value }) => setDescription(value)}
          validation={reNotEmpty}
          inputProps={{ maxLength: 1000 }}
          multiline={true}
          placeholder={intl.formatMessage({
            id: 'damaged.textfield-placeholder',
            defaultMessage: 'Describe the issue to help your insurance provider evaluate the claim (required)',
          })}
          error={!description && showingValidationErrors}
          className={classes.textField}
        />
        <Typography variant="body2" component="h1" className={classes.quantities}>
          <FormattedMessage
            id="damaged.upload"
            defaultMessage="Upload both photos of damaged shipping box & damaged items to avoid any processing delay."
          />
        </Typography>

        <Uploader
          endpoint={ClaimService.getUploadEndpoint(policy.shipment.id)}
          onSelection={() => setUploading(true)}
          onResponse={appendPhoto}
          onRemove={removePhoto}
          headers={{ 'jwt-token': policy.jwt.token }}
        />

        <EasyshipCheckbox
          label={intl.formatMessage({
            id: 'damaged.checkbox-label',
            defaultMessage:
              'I understand that I need to hold damaged items until the claim is resolved and failure to do so will result in the claim being denied. I certify that the shipping box is damaged and the information above is correct and truthful.',
          })}
          checked={checkCertify}
          onChange={() => setCheckCertify(!checkCertify)}
          error={!checkCertify && showingValidationErrors}
        />

        <Collapse in={showingValidationErrors} timeout={500}>
          <AlertNotification type="warning">
            <FormattedMessage id="global.checkbox-error-msg" defaultMessage="Please fill in the required fields." />
          </AlertNotification>
        </Collapse>

        {errorMessage === 'expired-session' && (
          <Grid item style={{ marginTop: 15 }}>
            <AlertNotification type="warning">
              <FormattedMessage
                id="global.expired-session"
                defaultMessage="This session has expired. Please refresh the page and and enter claim details to continue processing this claim."
              />
            </AlertNotification>
          </Grid>
        )}

        {errorMessage === 'default-error' && (
          <Grid item style={{ marginTop: 15 }}>
            <AlertNotification type="warning">
              <FormattedMessage
                id="global.default-error"
                defaultMessage="Something went wrong. Please refresh the page and try again."
              />
            </AlertNotification>
          </Grid>
        )}

        <Grid container direction="row" justifyContent="space-between" className={classes.buttonsContainer}>
          <Grid item>
            <Button color="secondary" onClick={handleBackClick}>
              <FormattedMessage id="global.back" defaultMessage="Back"></FormattedMessage>
            </Button>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleNextClick} pending={submitting} disabled={uploading}>
              <FormattedMessage id="global.next" defaultMessage="Next"></FormattedMessage>
            </Button>
          </Grid>
        </Grid>
      </Card>

      <FooterLinks companyName={policy.company.name}></FooterLinks>
    </Container>
  );
}
