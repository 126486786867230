import { useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Card, Container, Divider, Grid, Typography } from '@material-ui/core';

import OrderSummary from 'components/OrderSummary';
import ShipmentSummary from 'components/ShipmentSummary';
import Header from 'components/Header';
import FooterLinks from 'components/FooterLinks';
import ShipmentInfoCard from 'components/ShipmentInfoCard';

import { Policy } from 'services/claim/types';
import { useStyles } from './styles';

import { isPast } from 'services/date';

interface ISelectScreenState {
  policies?: Policy[];
}

export default function SelectScreen() {
  const history = useHistory();
  const classes = useStyles();
  const { policies } = (useLocation().state || {}) as ISelectScreenState;

  if (!policies) {
    history.push('/');
    return null;
  }

  const firstPolicy = policies[0];
  const unclaimedPolicies = policies.filter((policy: Policy) => !policy?.claim?.check_certify);
  const claimedPolicies = policies.filter((policy: Policy) => policy?.claim?.check_certify);

  const routeToClaim = (policy: Policy) => {
    if (isPast(policy.shipment.claim_filing_window_closes)) {
      history.push('/claim/not-eligible', { pastFilingWindow: true });
    } else {
      history.push('/claim', { policy });
    }
  };

  const routeToSummary = (policy: Policy) => {
    history.push('/claim/claim-summary', { policy });
  };

  return (
    <Container className={classes.root}>
      <Header totalSteps={3} currentStep={1}>
        <FormattedMessage id="home.headline" defaultMessage="Resolve an Issue" />
      </Header>

      <Grid item>
        {firstPolicy && (
          <Card className={classes.summaryCard}>
            <OrderSummary
              orderNumber={firstPolicy.shipment.platform_order_number}
              orderDate={firstPolicy.shipment.order_created_at}
              imgUrl={firstPolicy.company.logo_url}
              companyName={firstPolicy.company.name}
            />
            <Divider />
            <ShipmentSummary policy={firstPolicy} />
          </Card>
        )}
      </Grid>
      {unclaimedPolicies.length > 0 && (
        <div className={classes.subheader}>
          <Typography variant="h3" component="h1" align="center">
            <FormattedMessage id="select.subheader" defaultMessage="Please select the shipment affected." />
          </Typography>
        </div>
      )}

      <Grid item>
        {unclaimedPolicies.map((policy: Policy) => {
          return (
            <ShipmentInfoCard
              key={policy.shipment.id}
              trackingData={policy.trackings}
              items={policy.shipment_items}
              handleClick={() => routeToClaim(policy)}
            ></ShipmentInfoCard>
          );
        })}
      </Grid>

      {claimedPolicies.length > 0 && (
        <div>
          <div className={classes.subheader}>
            <Typography variant="h3" component="h1" align="center">
              <FormattedMessage id="select.claim_filed" defaultMessage="Claim filed" />
            </Typography>
          </div>

          {claimedPolicies.map((policy: Policy) => {
            return (
              <ShipmentInfoCard
                key={policy.shipment.id}
                claimed={true}
                claimStatus={
                  policy.claim?.processor === 'insureship'
                    ? policy.claim.provider_claim_status
                    : policy.claim?.review_state === 'approved'
                    ? policy.claim.payment_state
                    : policy.claim?.review_state
                }
                trackingData={policy.trackings}
                items={policy.shipment_items}
                handleClick={() => routeToSummary(policy)}
              ></ShipmentInfoCard>
            );
          })}
        </div>
      )}

      <FooterLinks companyName={firstPolicy.company.name}></FooterLinks>
    </Container>
  );
}
