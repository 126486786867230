import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from 'config/colors';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';

interface AlertNotificationProps {
  type?: string;
  children: string | React.ReactNode | React.ReactNode[];
}

export default function AlertNotification(props: AlertNotificationProps) {
  const useStyles = makeStyles(() => ({
    root: {
      padding: '0 15px 0 0',
      minHeight: 40,
      width: '100%',
      borderRadius: 8,
      backgroundColor: props.type === 'warning' ? COLORS.redLight : COLORS.tealLight,
      '& .MuiAlert-message': {
        color: COLORS.inkNormal,
        display: 'flex',
        alignItems: 'center',
      },
      '& .MuiAlert-icon': {
        margin: '9px 10px',
        padding: 0,
        color: props.type === 'warning' ? COLORS.redDark : COLORS.tealNormal,
        fontSize: 10,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Alert variant="filled" severity="error" className={classes.root} icon={<ErrorOutlinedIcon fontSize="small" />}>
      <Typography variant="body1">
        {props.children}
      </Typography>
    </Alert>
  );
}
