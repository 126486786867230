import { makeStyles } from '@material-ui/core/styles';
import MuiButton, { ButtonProps } from '@material-ui/core/Button';

import Thumper from '../Thumper';

interface IButtonProps extends ButtonProps {
  pending?: boolean;
  children?: any; // string | Element | Element[]; throws errors when react-intl is used
}

const useStyles = makeStyles(() => ({
  transparent: {
    opacity: 0,
  },
}));

export default function Button(props: IButtonProps) {
  const classes = useStyles();
  const { pending, children, ...buttonProps } = props;

  return (
    <MuiButton {...buttonProps}>
      {pending && <Thumper />}
      <span className={pending ? classes.transparent : ''}>{children}</span>
    </MuiButton>
  );
}
